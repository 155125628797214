import React, { Component, Fragment } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline'
import { Listbox, Transition } from '@headlessui/react'
const fuzzysort = require('fuzzysort');

class DropdownComboTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            label: "",
            searchValue: "",
            options: [],
            selected: {},
            default: {}
        }
    };

    componentDidMount() {
        this.setState({
            label: this.props.label,
            selected: this.props.selected,
            options: this.props.options,
            default: this.props.default,
            loader: this.props.loader,
            searchValue: this.props.searchValue,
            disabled: this.props.disabled
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            label: nextProps.label,
            selected: nextProps.selected,
            options: nextProps.options,
            loader: nextProps.loader,
            searchValue: nextProps.searchValue,
            disabled: nextProps.disabled
        })
    }

    filter() {
        let options = [];
        if (this.props.internalSearch) {
            let result = fuzzysort.go(this.state.searchValue, this.state.options.map((item) => { return item.name }));
            let old_options = JSON.parse(JSON.stringify(this.state.options));
            options = [];
            result.map((item) => {
                for (let i = 0; i < old_options.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_options[i].html = html_string;
                        options.push(old_options[i]);
                        old_options.splice(i, 1);
                        break;
                    }
                }
            });
        } else {
            options = this.state.options;
        }
        return options;
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <Listbox disabled={this.state.disabled} value={this.state.selected} onChange={(value) => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            }}>
                {({ open }) => (
                    <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">{this.state.label}</Listbox.Label>
                        <div className="mt-1 relative">

                            {
                                this.state.loader &&
                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }

                            {
                                this.state.disabled &&
                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center cursor-not-allowed"></div>
                            }

                            {

                                <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 sm:text-sm">
                                    <span className="flex items-center">
                                        {
                                            this.state.selected.id != 0 && this.state.selected.image &&
                                            <div className="h-5 w-5 mr-3 rounded-full flex-shrink-0 overflow-hidden justify-center flex items-center border bg-white">
                                                <img src={this.state.selected.image} alt="" className="w-auto" />
                                            </div>
                                        }
                                        <span className="block truncate">{this.state.selected.name}</span>
                                    </span>
                                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>
                            }
                            {
                                false &&
                                <div className="mt-1 relative flex flex-row rounded-md shadow-sm">
                                    {
                                        this.props.leftSection &&
                                        <span className="inline-flex items-center whitespace-no-wrap px-3 rounded-l-md border border-r-0 border-gray-300 sm:text-sm">
                                            {this.props.leftSectionLabel}
                                        </span>
                                    }
                                    <input
                                        autocomplete="off"
                                        type={this.props.type}
                                        disabled={this.state.disabled}
                                        className={(this.state.error ? " border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 " : " focus:ring-purple-500 focus:border-purple-500 border-gray-300 ") + (this.props.leftSection ? " rounded-r-md " : " rounded-md ") + " block w-full pr-10 px-4 py-2 focus:outline-none sm:text-sm"}
                                        placeholder={this.props.placeholder}
                                        value={this.state.searchValue}
                                        onChange={(event) => {
                                            if (this.props.onSearch) {
                                                this.props.onSearch(event.target.value)
                                            }
                                        }}
                                    />
                                </div>
                            }

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white border shadow-lg max-h-40 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    <input
                                        autocomplete="off"
                                        type={this.props.type}
                                        disabled={this.state.disabled}
                                        className={"focus:ring-purple-500 focus:border-purple-500 block w-full border-none rounded-b-none pr-10 px-4 py-2 focus:outline-none sm:text-sm"}
                                        placeholder={this.props.placeholder}
                                        value={this.state.searchValue}
                                        onChange={(event) => {
                                            if (this.props.onSearch) {
                                                this.props.onSearch(event.target.value)
                                            }
                                        }}
                                    />
                                    <div className="w-full border-b" />
                                    {this.filter().map((item) => (
                                        <Listbox.Option
                                            key={item.id}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-900'
                                                )
                                            }
                                            value={item}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <div className="flex items-center">
                                                        {
                                                            item.image &&
                                                            <div className="h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center border bg-white">
                                                                <img src={item.image} alt="" className="w-auto" />
                                                            </div>
                                                        }
                                                        {
                                                            !this.props.facebookLocations &&
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                                                            >
                                                                {item.name}
                                                            </span>
                                                        }
                                                        {
                                                            this.props.facebookLocations &&
                                                            <div
                                                                className="flex flex-row flex-1"
                                                            >
                                                                <div className="flex flex-1 justify-start">{item.name}{item.region ? (", " + item.region) : ""}{item.country_name ? (", " + item.country_name) : ""}</div>
                                                                <div>{item.type ? item.type.replace("_", " ") : ""}</div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-purple-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        )
    }
}

export default DropdownComboTailwind;
