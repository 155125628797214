import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, DuplicateIcon, ExclamationIcon } from '@heroicons/react/outline'
import SwitchTailwind from './switchTailwind';
import DropdownTailwind from './dropdownTailwind';

class WarningModalTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            dropDownSelectedRender: {}
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            copyInput: this.props.copyInput,
            loading: this.props.loading,
            dropDownSelectedRender: this.props.dropDownSelectedRender ? this.props.dropDownSelectedRender : {}
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            inputError: nextProps.inputError,
            copyInput: nextProps.copyInput,
            disableSubmitButton: nextProps.disableSubmitButton,
            loading: nextProps.loading,
            dropDownSelectedRender: nextProps.dropDownSelectedRender ? nextProps.dropDownSelectedRender : {}
        })
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-151 inset-0 overflow-y-auto" onClose={() => {
                    this.props.onClose();
                    this.setState({
                        input: ""
                    })
                }}
                    style={{ zIndex: 999 }}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">

                                <div>
                                    <div className={(!this.props.decisionModal ? "bg-red-100" : "bg-purple-100") + " mx-auto flex items-center justify-center h-20 w-20 rounded-full"}>
                                        {
                                            !this.props.decisionModal &&
                                            <ExclamationIcon className="h-8 w-8 text-red-600 " aria-hidden="true" />
                                        }
                                        {
                                            this.props.decisionModal &&
                                            <BeakerIcon className="h-8 w-8 text-purple-600 " aria-hidden="true" />
                                        }
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5 mb-4">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {this.props.title}
                                        </Dialog.Title>
                                        <div className="mt-2 pb-4 px-4">
                                            <p className="text-sm text-gray-500">
                                                {this.props.description}
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        this.state.copyInput !== "" &&
                                        this.props.showInput &&
                                        <div className="mt-3">
                                            <label htmlFor="warning-name" className="block text-xs font-medium text-gray-700">
                                                {"Name"}
                                            </label>
                                            <div className="mt-1 flex rounded-md shadow-sm">
                                                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                    <input
                                                        autocomplete="off"
                                                        type="text"
                                                        name="warning-name"
                                                        readOnly={true}
                                                        id="warning-name"
                                                        className="block w-full rounded-none rounded-l-md px-3 sm:text-sm border-gray-300"
                                                        value={this.state.copyInput}
                                                    />
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            input: this.state.copyInput
                                                        }, () => {
                                                            if (this.props.onInput) {
                                                                this.props.onInput(this.state.input)
                                                            }
                                                        })
                                                    }}
                                                    type="button"
                                                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                >
                                                    <DuplicateIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span>Copy</span>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.props.showInput &&
                                        <div className="mt-3">
                                            <label htmlFor="company-name" className="block text-xs font-medium text-gray-700">
                                                {this.props.inputTitle}
                                            </label>
                                            <input
                                                autocomplete="off"
                                                value={this.state.input}
                                                onChange={(event) => {
                                                    this.setState({
                                                        input: event.target.value
                                                    }, () => {
                                                        if (this.props.onInput) {
                                                            this.props.onInput(this.state.input)
                                                        }
                                                    })
                                                }}
                                                type="text"
                                                name="company-name"
                                                id="company-name"
                                                className={(this.state.inputError ? "text-red-900 border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"}
                                            />
                                            {
                                                this.state.inputError &&
                                                <p className="mt-2 text-xs text-red-600" id="email-error">
                                                    {this.state.inputError}
                                                </p>
                                            }
                                        </div>
                                    }
                                </div>
                                {
                                    this.props.dropDown &&
                                    <>
                                        <div className="mt-5">
                                            <DropdownTailwind
                                                small={true}
                                                smallText={true}
                                                options={this.props.dropDownOptions}
                                                selected={this.state.dropDownSelectedRender}
                                                onChange={(value) => {
                                                    if (this.props.onDropDownSelect) {
                                                        this.props.onDropDownSelect(value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>

                                }
                                <div className={`flex flex-row-reverse ${this.props.dropDown ? "mt-10" : "mt-8"}`}>
                                    {
                                        !this.props.noSubmitButton &&
                                        <button
                                            type="button"
                                            className={(this.props.decisionModal ? "bg-indigo-600 focus:ring-indigo-500 hover:bg-indigo-700 " : "bg-red-600 focus:ring-red-500 ") + (this.state.disableSubmitButton ? "cursor-not-allowed bg-opacity-50 " : "hover:bg-red-700 focus:ring-red-500 ") + " flex flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm relative"}
                                            onClick={() => {
                                                this.props.onSubmit();
                                                this.setState({
                                                    input: "",
                                                    loading: true,
                                                })
                                            }}
                                        >
                                            {this.props.submitButtonText}
                                            {this.state.loading &&
                                                <div className={`w-full h-full absolute top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center ${this.props.decisionModal ? "bg-indigo-600" : "bg-red-600"}`}>
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class={"w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"}></div>
                                                </div>
                                            }
                                        </button>
                                    }
                                    {
                                        !this.props.noCloseButton &&
                                        <button
                                            disabled={this.state.loading}
                                            type="button"
                                            className={`relative mt-3 flex flex-1 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm ${this.state.loading ? "cursor-not-allowed bg-gray-500 text-white" : "bg-white text-gray-700 hover:bg-gray-50"}`}
                                            onClick={() => {
                                                this.props.onClose();
                                                this.setState({
                                                    input: ""
                                                })
                                            }}
                                        >
                                            {this.props.cancelButtonText}
                                        </button>

                                    }
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default WarningModalTailwind;
