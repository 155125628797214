import moment from 'moment';
import React, { Component, Fragment } from 'react';
import cn from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, PlayIcon, PauseIcon, ClockIcon, ExclamationIcon, SaveIcon, TrashIcon, PaperClipIcon, XIcon, PhotographIcon, PencilAltIcon, PlusIcon, TemplateIcon, CursorClickIcon, CollectionIcon, PlusCircleIcon, SearchIcon, ViewGridIcon, MenuIcon, ScissorsIcon, ArrowsExpandIcon, FilmIcon, DocumentDownloadIcon, ChevronUpIcon, NewspaperIcon, ChartBarIcon, PuzzleIcon, BanIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';
import CreateOrderCreativeEdit from '../moduleFiles/createOrderCreativeEdit';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import Pusher from 'pusher-js';
import CreateOrderCreativeCropper from '../moduleFiles/createOrderCreativeCropper';
import CreativesChannelsModal from '../moduleFiles/creativeChannelsModal';
import SlideoutTailwindAsset from '../moduleFiles/slideoutTailwindAsset';
import { SlideDown } from "react-slidedown";
import SweetAlert from 'sweetalert-react'
// Pusher.logToConsole = true;

var pusherService = null;

class OrderCreatives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            order: {},
            channels: {},
            temp_creatives: [],
            ad_template: [],
            creatives: [],
            tabs: [
                { id: 1, name: 'Uploaded creatives', value: 'uploaded', icon: PhotographIcon },
                { id: 2, name: 'Ad templates', value: 'ads', icon: TemplateIcon },
            ],
            tab: { id: 1, name: 'Uploaded creatives', value: 'uploaded', icon: PhotographIcon },
            open: false,
            client: {},
            cropper_loader: false,
            open_cropper: false,
            select_channel: false,
            selected_channels: [],
            preview_data: false,
            ai_result: false,
            open_creatives: {
                facebook: true,
                linkedin: true,
                twitter: true,
                tiktok: true,
                snapchat: true,
            },
            asset_view: "grid",
            asset_seach_value: "",
            tab_view: "list",
            seach_value: "",
            remove_error: false,
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        };
    }

    componentWillMount() {

    }

    async componentDidMount() {

        pusherService = new Pusher('a07698cf59a1788c44d4', {
            cluster: 'eu'
        });

        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getCreatives();
        this.props.updateStatistics();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        checkDelete: async (id) => {
            try {
                let response = await this.calls.checkDelete({ id: id });
                if (response.data) {
                    //if (response.data.exist) {
                    this.state.creatives = this.state.creatives.map((item) => { if (item.id === id) { item.non_delete = response.data.exist ? true : false } return item });
                    await this.promisedSetState({ creatives: this.state.creatives });
                    //}
                    //await this.promisedSetState({ remove: response.data });
                }
            } catch (error) { }
        },
        getCreatives: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: true });
                try {
                    let response = await this.calls.creatives();
                    await this.promisedSetState({
                        order: response.data,
                        creatives: response.data.creatives.filter((item) => { return !item.adTemplate }),
                        ad_template: response.data.creatives.filter((item) => { return item.adTemplate }).map((item) => { item.adData.internal_id = item.id; return item.adData; }),
                        meta: response.meta,
                        client: { id: response.data.client, ...response.client },
                        channels: response.client.channels
                    });
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                    //this.state.creatives.map((item) => { this.functions.checkDelete(item.id); });
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                resolve();
            });
        },
        updateCreatives: async () => {
            let promises = [];
            for (let i = 0; i < this.state.creatives.length; i++) {
                if (this.state.creatives[i].updated) {
                    promises.push(this.calls.update({
                        name: this.state.creatives[i].name,
                        website: this.state.creatives[i].website,
                        headline: this.state.creatives[i].headline,
                        body: this.state.creatives[i].body,
                        description: this.state.creatives[i].description,
                        page: this.state.creatives[i].page,
                        cta: this.state.creatives[i].cta,
                    }, this.state.creatives[i].id));
                }
            }
            let response = await Promise.all(promises);
            if (Array.isArray(promises)) {
                response.map((item) => {
                    this.state.creatives = this.state.creatives.map((inner_item) => {
                        if (inner_item.id === item.data.id) {
                            inner_item.updated = false;
                            inner_item.loading = false;
                        }
                        return inner_item;
                    })
                });
            }
            await this.promisedSetState({ creatives: this.state.creatives });
        },
        removeCreative: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.creatives.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ creatives: this.state.creatives });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ creatives: this.state.creatives.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.creatives.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
            this.props.updateStatistics();
        },
        removeCreatives: async () => {
            await this.promisedSetState({ remove: null });
            let promises = [];
            for (let i = 0; i < this.state.creatives.length; i++) {
                if (this.state.creatives[i].selected) {
                    promises.push(this.calls.remove(this.state.creatives[i].id));
                }
            }
            let response = await Promise.all(promises);
            if (Array.isArray(promises)) {
                response.map((item) => {
                    this.state.creatives = this.state.creatives.filter((inner_item) => {
                        return inner_item.id !== item.data.id;
                    })
                });
            }

            await this.promisedSetState({ creatives: this.state.creatives });
            this.props.updateStatistics();
        },
        createAdTemplate: async (files) => {
            try {
                for (let i in files) {
                    let response = await this.calls.createAdTemplate({ adData: files[i], order: this.state.order.id });
                    if (response && response.data) {
                        this.state.ad_template.map((item) => {
                            if (item.id === response.data.adData.id) {
                                item.internal_id = response.data.id;
                            }
                            return item
                        });
                        await this.promisedSetState({ ad_template: this.state.ad_template });
                    }
                }
            } catch (error) {

            }
        },
        updateAdTemplate: async (files) => {
            try {
                for (let i in files) {
                    let internal_id = files[i].internal_id;
                    delete files[i].internal_id;
                    await this.calls.updateAdTemplate(files[i], internal_id);
                    //
                }
            } catch (error) {

            }
        },
        deleteAdTemplate: async (id) => {
            try {
                await this.calls.remove(id);
                await this.promisedSetState({ remove: null, ad_template: this.state.ad_template.filter((item) => { return item.internal_id !== id }) });
            } catch (error) {

            }
        },
        createMerge: async () => {

            let merged = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name_by_channels: { facebook: this.state.creatives.filter((item) => { return item.selected })[0].name },
                type: "merge",
                video: this.state.creatives.filter((item) => { return item.selected })[0].type === "video",
                image: this.state.creatives.filter((item) => { return item.selected })[0].type === "image",
                files: JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected }))),
                headlines: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                bodies: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                descriptions: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                links: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                call_to_actions: [{
                    id: Math.floor((Math.random() * 9999999999) + 1), value: {
                        "name": "No Button",
                        "id": "NO_BUTTON",
                        "type": "NO_BUTTON"
                    }
                }],
                objective: { id: 0, name: "Click here ..." }
            };

            //CHECK RATIOS
            let vertical = merged.files.filter((item) => { return item.ratio === "9:16" });
            let horizontal = merged.files.filter((item) => { return item.ratio === "1.91:1" });
            let regular = merged.files.filter((item) => { return item.ratio !== "1.91:1" && item.ratio !== "9:16" });

            if (vertical.length > 1) {
                merged.error = true;
                merged.message = "Only one 9:16 format is allowed";
            } else if (horizontal.length > 1) {
                merged.error = true;
                merged.message = "Only one 1.91:1 format is allowed";
            } else if (regular.length > 1) {
                merged.error = true;
                merged.message = "Only one other format other than 9:16 and 1.91:1 is allowed";
            }

            if (merged.files.length > 3) {
                merged.error = true;
                merged.message = "Only 3 creatives allowed";
            }

            merged.allowed_channels = { facebook: true };
            merged.allowed_types = { regular: true };
            merged.allowed_multiple = true;

            this.state.temp_creatives.push(merged);

            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });

            //this.state.creatives.push(merged);
            //this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ temp_creatives: this.state.temp_creatives, select_channel: true });
            //await this.form.set();
            //this.props.setSteps();
        },
        createCarousel: async () => {
            let carousel = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name_by_channels: {
                    facebook: this.state.creatives.filter((item) => { return item.selected })[0].name,
                    linkedin: this.state.creatives.filter((item) => { return item.selected })[0].name,
                    tiktok: "",
                    snapchat: "",
                    twitter: this.state.creatives.filter((item) => { return item.selected })[0]
                },
                type: "carousel",
                files: JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected }).map((item) => {
                    if (item.type === "image") {
                        if (!item.name_by_channels) {
                            item.name_by_channels = {
                                facebook: item.name,
                                linkedin: item.name,
                                tiktok: item.name,
                                snapchat: item.name,
                                twitter: item.name
                            }
                        }
                        if (!item.allowed_channels) {
                            item.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                        }
                        if (!item.allowed_types) {
                            item.allowed_types = { regular: true };
                        }
                        if (!item.allowed_multiple) {
                            item.allowed_multiple = true;
                        }
                    }
                    return item;
                }))),
            };

            carousel.allowed_channels = { facebook: true, linkedin: true, twitter: true };
            carousel.allowed_types = { regular: true };
            carousel.allowed_multiple = true;
            this.state.temp_creatives.push(carousel);
            //this.state.creatives.push(carousel);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ temp_creatives: this.state.temp_creatives, select_channel: true });
            //await this.form.set();
            //this.props.setSteps();
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        this.functions.uploadVideo(files[i]);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        this.functions.uploadImage(files[i]);
                    }
                    const randomString = Math.random().toString(36);
                    this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    }, true);
                }
            }
        },
        uploadImage: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'image'
            };
            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.creatives.push(files_object);
            await this.promisedSetState({ creatives: this.state.creatives });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.image(formData);
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            } catch (error) {
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
            this.props.updateStatistics();
        },
        uploadVideoOld: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                type: 'video'
            };
            this.state.creatives.push(files_object);
            await this.promisedSetState({ creatives: this.state.creatives });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.video(formData);
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            } catch (error) {
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
            this.props.updateStatistics();
        },
        uploadVideo: async (file) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'video'
            };
            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.creatives.push(files_object);
            await this.promisedSetState({ creatives: this.state.creatives });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                //console.log("response", response);
                if (response && response.status === 200) {
                    self.state.creatives = self.state.creatives.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ creatives: self.state.creatives });
                    self.props.updateStatistics();
                } else {
                    self.state.creatives = self.state.creatives.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ creatives: self.state.creatives });
                }
                try {
                    pusherService.unsubscribe(channel_id);
                } catch (error) {
                    console.log(error);
                }
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video(formData, channel_id);
            } catch (error) {
                console.log(error);
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        creatives: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderCreatives?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        update: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCreative?id=" + id;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCreative?id=" + id;
            return apiRegister.call(options, url);
        },
        createAdTemplate: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createAdTemplate?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateAdTemplate: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdTemplate?id=" + id;
            return apiRegister.call(options, url);
        },
        checkDelete: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/checkCreativeDelete?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        videoOld: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        video: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?order=" + this.functions.getIdFromPath() + "&large=true" + "&channel=" + channel_id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        prettySize: (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={() => {

                        }}
                        hideUserDropdown={true}
                        breadcrumb={"Assets"}
                        history={this.props.history}
                        showClient={true}
                        lockClient={false}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading assets ...</div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={async (value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            if (this.state.remove.adTemplate) {
                                this.functions.deleteAdTemplate(this.state.remove.id);
                            } else {
                                if (this.state.remove.multiple) {
                                    this.functions.removeCreatives();
                                } else {
                                    this.functions.removeCreative();
                                }
                            }
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove_error ? true : false}
                    title={"Creatives remove error"}
                    description={'Remove Ad Template or Drafts that are related to this creatives first.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    noSubmitButton={true}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove_error: false });
                    }}
                />


                {/*SELECT CHANNELS POP UP*/}
                <CreativesChannelsModal
                    open={this.state.select_channel}
                    client={this.state.client}
                    title={"Select channels"}
                    channels={Object.keys(this.state.channels)}
                    carousel={this.state.temp_creatives.filter((item) => { return item.type === "carousel" }).length > 0 ? true : false}
                    merge={this.state.temp_creatives.filter((item) => { return item.type === "merge" }).length > 0 ? true : false}
                    collection={false}
                    button={"Next"}
                    size_error={false}
                    onClose={async () => {
                        await this.promisedSetState({
                            select_channel: false,
                            temp_creatives: [],
                            selected_channels: []
                        });

                    }}
                    onCreate={async (channels) => {
                        await this.promisedSetState({
                            selected_channels: channels,
                            select_channel: false,
                            open: true
                        });

                    }}
                />

                {/* CROPP CREATIVE */}
                <SlideoutTailwind
                    title={"Crop creatives"}
                    large={true}
                    submitButtonText={"Save"}
                    loader={this.state.cropper_loader}
                    open={this.state.open_cropper}
                    onCancel={() => {
                        this.setState({
                            open_cropper: false
                        })
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativeCropper.functions.crop();
                    }}
                >
                    {
                        this.state.open_cropper &&
                        <CreateOrderCreativeCropper
                            ref="CreateOrderCreativeCropper"
                            order={this.state.order.id}
                            files={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected })))}
                            onNew={async (media) => {
                                if (media.type === "image") {
                                    media.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                                    media.allowed_types = { regular: true };
                                    media.allowed_multiple = true;
                                    media.cropped = true;
                                } else if (media.type === "video") {
                                    media.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
                                    media.allowed_types = { regular: true };
                                    media.allowed_multiple = true;
                                    media.cropped = true;
                                }
                                this.state.creatives.push(media);
                                await this.promisedSetState({
                                    creatives: this.state.creatives,
                                    open_cropper: false
                                });
                                //await this.form.set();
                            }}
                            onLoader={(value) => {
                                this.setState({
                                    cropper_loader: value
                                })
                            }}
                        />
                    }
                </SlideoutTailwind>


                {/* SLIDEOUT UPDATE */}
                <SlideoutTailwindAsset
                    title={"Update creative"}
                    full={true}
                    preview_data={this.state.preview_data ? this.state.preview_data : false}
                    disabled={this.state.disabled}
                    submitButtonText={"Save"}
                    client={this.state.client}
                    open={this.state.open}
                    noPadding={true}
                    onChannel={(channel) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.swithChannel(channel);
                        } else this.refs.CreateOrderCreativeEdit.functions.swithChannel(channel);
                    }}
                    onPreviewChannel={(channel) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.previewChannel(channel);
                        } else this.refs.CreateOrderCreativeEdit.functions.previewChannel(channel);
                    }}
                    onUpdateChat={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.updateOpenAi(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.updateOpenAi(data);
                    }}
                    onAddHeadlines={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.addHeadlines(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.addHeadlines(data);
                    }}
                    onAddDescriptions={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.addDescriptions(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.addDescriptions(data);
                    }}
                    onRemoveDescription={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.removeDescription(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.removeDescription(data);
                    }}
                    onRemoveHeadline={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.removeHeadline(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.removeHeadline(data);
                    }}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                    onCancel={() => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.state.ad_template = this.state.ad_template.map((item) => {
                            item.selected = false;
                            return item;
                        });

                        this.promisedSetState({
                            ad_template: this.state.ad_template,
                            creatives: this.state.creatives,
                            open: false,
                            disabled: false,
                            temp_creatives: [],
                            collection: false,
                            selected_channels: [],
                            preview_data: false,
                            ai_result: false
                        });
                    }}
                    onSubmit={() => {
                        //this.refs.CreateOrderCreativeEdit.functions.push();
                        if (!this.state.collection) {
                            this.refs.CreateOrderCreativeEdit.functions.save();
                        } else {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.save();
                        }
                    }}
                    onSecondarySubmit={() => {
                        //this.refs.CreateOrderCreativeEdit.functions.pushCarousel();
                    }}
                >
                    {
                        this.state.open &&
                        <CreateOrderCreativeEdit
                            ref="CreateOrderCreativeEdit"
                            campaigns={[]}
                            adgroups={[]}
                            ads={[]}
                            files={this.state.temp_creatives.length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives)) : JSON.parse(JSON.stringify(this.state.ad_template.filter((item) => { return item.selected })))}
                            carousel={this.state.temp_creatives.filter((item) => { return item.type === "carousel" }).length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives[0])) : this.state.ad_template.filter((item) => { return item.selected && item.type === "carousel" }).length > 0 ? JSON.parse(JSON.stringify(this.state.ad_template.filter((item) => { return item.selected && item.type === "carousel" })[0])) : null}
                            merge={this.state.temp_creatives.filter((item) => { return item.type === "merge" }).length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives[0])) : this.state.ad_template.filter((item) => { return item.selected && item.type === "merge" }).length > 0 ? JSON.parse(JSON.stringify(this.state.ad_template.filter((item) => { return item.selected && item.type === "merge" })[0])) : null}
                            assetItems={this.state.creatives.filter((item) => { return item.selected }).length > 0 ? JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected }))) : []}

                            channels={this.state.channels}
                            client={{ id: this.state.order.client }}
                            selected_channels={this.state.selected_channels}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                            }}
                            onUpdateChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSave={async (files) => {
                                console.log("FILES", files);
                                this.state.ad_template = this.state.ad_template.map((item) => {
                                    files.map((inner_item) => {
                                        if (inner_item.id === item.id) {
                                            item = inner_item;
                                        }
                                    });
                                    item.selected = false;
                                    return item;
                                });

                                await this.promisedSetState({
                                    saved_success: true,
                                    ad_template: this.state.ad_template,
                                    open: false,
                                    temp_creatives: []

                                });
                                this.functions.updateAdTemplate(files);
                            }}
                            onAddCreative={async (files) => {
                                this.state.creatives = this.state.creatives.map((item) => {
                                    if (item.selected) {
                                        item.non_delete = true;
                                    }
                                    item.selected = false;
                                    return item;
                                });
                                //this.functions.createCreative(files);
                                if (this.state.ad_template.length < 1) {
                                    this.state.ad_template = files
                                } else {
                                    this.state.ad_template = this.state.ad_template.concat(files);
                                }
                                await this.promisedSetState({
                                    selected_channels: [],
                                    ad_template: this.state.ad_template,
                                    saved_success: true,
                                    creatives: this.state.creatives,
                                    open: false,
                                    temp_creatives: []
                                });
                                this.functions.createAdTemplate(files);
                                this.props.updateStatistics();
                                //this.form.set();

                            }}
                        /*
                        onSave={async (files) => {
                            this.state.creatives = this.state.creatives.map((item) => {
                                files.map((inner_item) => {
                                    if (inner_item.id === item.id) {
                                        item = inner_item;
                                        item.selected = false;
                                        item.updated = true;
                                        item.loading = true;
                                    }
                                });
                                return item;
                            });
                            await this.promisedSetState({
                                creatives: this.state.creatives,
                                open: false
                            });
                            this.functions.updateCreatives();
                        }}*/
                        />
                    }
                </SlideoutTailwindAsset>

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {/* CREATIVES */}
                        {
                            <div className="flex flex-row">
                                {
                                    [
                                        { id: 1, name: 'Uploaded creatives', value: 'uploaded', icon: PhotographIcon },
                                    ].map((tab, index) => {
                                        return (
                                            <Fragment>
                                                <div onClick={() => {
                                                    this.promisedSetState({
                                                        tab: tab
                                                    })
                                                }}
                                                    style={tab.id === this.state.tab.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                    className={(tab.id === this.state.tab.id ? "bg-white" : "text-gray-300 hover:text-gray-700 bg-gray-50") + " shadow py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                    <tab.icon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                    <div className="flex flex-1 truncate">
                                                        <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                            {tab.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-2"></div>
                                            </Fragment>
                                        )
                                    })
                                }
                                <div className="flex flex-1"></div>
                            </div>
                        }
                        {
                            <div className=" bg-white flex flex-1 rounded-b-lg shadow flex-col">
                                <div className="p-6">

                                    <div className="">
                                        <div className={"col-span-12"}>
                                            <div key={this.state.theInputKeyOne || ''} className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    multiple
                                                    accept={"image/png, image/jpg, image/jpeg, image/gif, video/*"}
                                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                />
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-6 w-6 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF, MP4, MOV up to 100MB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.state.creatives.length > 0 &&
                                        <div className={"w-full mt-5"}>
                                            <div className='flex w-full mb-4'>
                                                <div className='flex flex-1 items-center'>
                                                    <p className="text-xs font-bold">Uploaded content</p>
                                                </div>
                                                <div id="asset_seach" className='w-24 flex transition-all duration-700 rounded-md mr-2 relative'>
                                                    <input
                                                        autocomplete="off"
                                                        onBlur={() => {
                                                            document.getElementById('asset_seach').classList.remove('w-120');
                                                            document.getElementById('asset_seach').classList.add('w-24');
                                                        }}
                                                        onFocus={() => {
                                                            document.getElementById('asset_seach').classList.remove('w-24');
                                                            document.getElementById('asset_seach').classList.add('w-120');
                                                        }}

                                                        className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                                        placeholder={"Search"}
                                                        value={this.state.asset_seach_value}
                                                        onChange={async (event) => {
                                                            await this.promisedSetState({
                                                                asset_seach_value: event.target.value
                                                            })

                                                        }}
                                                    />
                                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                        <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <div className='flex'>

                                                    <div onClick={async () => {
                                                        await this.promisedSetState({
                                                            asset_view: "grid"
                                                        })
                                                    }} className={(this.state.asset_view !== "grid" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5  mr-2 px-2 "}>
                                                        <ViewGridIcon className=" h-5 w-5 " />
                                                    </div>
                                                    <div onClick={async () => {
                                                        await this.promisedSetState({
                                                            asset_view: "list"
                                                        })
                                                    }} className={(this.state.asset_view !== "list" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5 px-2 "}>
                                                        <MenuIcon className=" h-5 w-5 " />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='w-full'>
                                                <div className={"max-h-60 max overflow-hidden overflow-y-auto relative" + (this.state.asset_view === "list" ? " w-full" : " grid grid-cols-6 gap-4 ")}>
                                                    {
                                                        this.state.asset_view === "list" &&
                                                        <div className='flex flex-row w-full items-center sticky top-0 z-55 bg-white'>
                                                            <div className='mr-8'>
                                                                <div className='h-10 w-20'>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-1'>
                                                                <div className='grid grid-cols-8 w-full text-sm font-semibold'>
                                                                    <div className='col-span-4'>
                                                                        Name
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Type
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Ratio
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Size
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Cropped
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.creatives.filter((item) => {
                                                            if (this.state.asset_seach_value !== "") {
                                                                if (item.name.toLowerCase().includes(this.state.asset_seach_value.toLowerCase())) {
                                                                    return true;
                                                                } else return false;
                                                            } else return true;
                                                        }).map((file) => {
                                                            if (this.state.asset_view === "list") {
                                                                return (
                                                                    <Fragment>
                                                                        <div className='flex flex-row w-full items-center py-2 border-t'>
                                                                            <div className='mr-8'>
                                                                                <div className='h-10 w-20 flex flex-row'>
                                                                                    <div onClick={() => {
                                                                                        if (!file.loading) {
                                                                                            file.selected = !file.selected;
                                                                                            this.promisedSetState({
                                                                                                creatives: this.state.creatives
                                                                                            });
                                                                                            //this.functions.checkDelete(file.id);
                                                                                        }
                                                                                    }} key={file.source} className={(file.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                        {
                                                                                            file.selected &&
                                                                                            <CheckIcon className="h-5 w-5" />
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        file.type === "image" && !file.loading &&
                                                                                        <div className="object-cover w-10 h-10 rounded-md inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        file.type === "video" && !file.loading &&
                                                                                        <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-10 h-10 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                            <source src={file.url} type="video/mp4" />
                                                                                        </video>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex flex-1'>
                                                                                <div className='grid grid-cols-8 w-full text-sm items-center mt-2'>
                                                                                    <div className='col-span-4 truncate pr-1'>
                                                                                        {(file.name && file.name !== "" ? file.name : "-")}
                                                                                    </div>
                                                                                    <div className='col-span-1 truncate px-1'>
                                                                                        {file.type}
                                                                                    </div>
                                                                                    <div className='col-span-1 truncate px-1'>
                                                                                        {file.ratio}
                                                                                    </div>
                                                                                    <div className='col-span-1 px-1'>
                                                                                        {this.renders.prettySize(file.size)}
                                                                                    </div>
                                                                                    <div className='col-span-1 px-1'>

                                                                                        {
                                                                                            file.cropped &&
                                                                                            <div className="text-sm ">
                                                                                                <div className="flex justify-items-start items-center">
                                                                                                    <ScissorsIcon className="h-4 w-4 mr-1" />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div onClick={() => {
                                                                        if (!file.loading) {
                                                                            file.selected = !file.selected;
                                                                            this.promisedSetState({
                                                                                creatives: this.state.creatives
                                                                            });
                                                                            //this.functions.checkDelete(file.id);
                                                                        }
                                                                    }} key={file.source} className={(file.error ? "border-red-500 bg-red-100 hover:border-red-500 " : "") + (file.selected ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " rounded-lg overflow-hidden border-1.5 shadow-sm relative cursor-pointer items-center col-span-2 flex h-16"}>

                                                                        <div className='flex flex-1 h-full w-full items-center'>
                                                                            <div className="flex">
                                                                                {
                                                                                    file.loading &&
                                                                                    <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                        <div className="ml-2 text-xs">
                                                                                            Loading ...
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    file.type === "image" && !file.loading &&
                                                                                    <div className="object-cover w-16 h-16 inset-0 rounded-md pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    file.type === "video" && !file.loading &&
                                                                                    <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className="w-16 h-16 rounded-md inset-0 pointer-events-none group-hover:opacity-75">
                                                                                        <source src={file.url} type="video/mp4" />
                                                                                    </video>
                                                                                }

                                                                            </div>
                                                                            <div className="flex flex-1 flex-col text-sm overflow-hidden w-40 ml-2">
                                                                                <div className="relative truncate whitespace-no-wrap">{file.name}</div>
                                                                                {
                                                                                    file.type === "image" &&
                                                                                    <div className="inline-flex text-xs relative text-gray-700">
                                                                                        <PhotographIcon className="mr-0.5 h-4 w-4" />
                                                                                        <span>Image</span>
                                                                                        {
                                                                                            file.ratio &&
                                                                                            <>
                                                                                                <div className="inline-flex ml-2">
                                                                                                    <ArrowsExpandIcon className="mr-0.5 h-4 w-4" />
                                                                                                    <span className="">Ratio:({file.ratio})</span>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        <div className='inline-flex ml-2 '>
                                                                                            <DocumentDownloadIcon className="mr-0.5 h-4 w-4" />
                                                                                            <span>Size: ({this.renders.prettySize(file.size)})</span>
                                                                                        </div>
                                                                                        {
                                                                                            file.cropped &&
                                                                                            <div className='inline-flex ml-2 '>
                                                                                                <ScissorsIcon className="mr-0.5 h-4 w-4" />
                                                                                                <span>Cropped</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    file.type === "video" &&
                                                                                    <div className="inline-flex text-xs relative text-gray-700">
                                                                                        <FilmIcon className="mr-0.5 h-4 w-4" />
                                                                                        <span>Video</span>
                                                                                        {
                                                                                            file.ratio &&
                                                                                            <>
                                                                                                <div className="inline-flex rml-2 ">
                                                                                                    <ArrowsExpandIcon className="mr-0.5 h-4 w-4" />
                                                                                                    <span className="">Ratio:({file.ratio})</span>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            file.cropped &&
                                                                                            <div className='inline-flex ml-2 '>
                                                                                                <ScissorsIcon className="mr-0.5 h-4 w-4" />
                                                                                                <span>Cropped</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            false &&
                                                                            <div className='pr-2 flex flex-1'>
                                                                                <div className={"text-center rounded-md py-1.5 px-5 bg-gray-300 text-sm" + (file.selected ? " text-purple-300" : " hover:text-purple-500")}>
                                                                                    {file.selected ? "Selected" : "Select"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>


                                        </div>



                                    }
                                    {
                                        this.state.creatives.length > 0 &&
                                        <div className="flex flex-row mb-6 mt-6">
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state.creatives.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length > 1) {
                                                            this.functions.createCarousel();
                                                        }
                                                    }}
                                                    className={(this.state.creatives.filter((item) => {
                                                        return item.selected && (item.type == "image" || item.type == "video")
                                                    }).length > 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    <span>Carousel</span>
                                                    <CollectionIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                            {
                                                this.state.channels.facebook &&
                                                <div className="mr-2">
                                                    <button
                                                        onClick={() => {
                                                            if (this.state.creatives.filter((item) => {
                                                                return item.selected && (item.type == "image" || item.type == "video")
                                                            }).length > 1 && this.state.creatives.filter((item) => {
                                                                return item.selected && (item.type == "image" || item.type == "video")
                                                            }).length < 4 && this.state.creatives.filter((item) => {
                                                                return item.selected && (item.type == "image" || item.type == "video")
                                                            }).map((item) => { return item.type }).every((val, i, arr) => val === arr[0]) &&

                                                                // HERE

                                                                (((this.state.creatives.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                    this.state.creatives.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1) &&
                                                                    this.state.creatives.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) ||

                                                                    (this.state.creatives.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 &&
                                                                        (this.state.creatives.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1 ||
                                                                            this.state.creatives.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1)) ||

                                                                    ((this.state.creatives.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                        this.state.creatives.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) &&
                                                                        this.state.creatives.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1)
                                                                )

                                                            ) {
                                                                this.functions.createMerge();
                                                            }
                                                        }}
                                                        className={
                                                            (this.state.creatives.filter((item) => {
                                                                return item.selected && (item.type == "image" || item.type == "video")
                                                            }).length > 1 && this.state.creatives.filter((item) => {
                                                                return item.selected && (item.type == "image" || item.type == "video")
                                                            }).length < 4 && this.state.creatives.filter((item) => {
                                                                return item.selected && (item.type == "image" || item.type == "video")
                                                            }).map((item) => { return item.type }).every((val, i, arr) => val === arr[0]) &&

                                                                (((this.state.creatives.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                    this.state.creatives.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1) &&
                                                                    this.state.creatives.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) ||

                                                                    (this.state.creatives.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 &&
                                                                        (this.state.creatives.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1 ||
                                                                            this.state.creatives.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1)) ||

                                                                    ((this.state.creatives.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                        this.state.creatives.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) &&
                                                                        this.state.creatives.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1)
                                                                )
                                                                ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                    >
                                                        <span>Combine</span>
                                                        <TemplateIcon className="ml-2 h-5 w-5" />
                                                    </button>
                                                </div>
                                            }
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state.creatives.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length > 0) {
                                                            this.promisedSetState({
                                                                select_channel: true
                                                            });
                                                        }
                                                        console.log(this.state, "=========STATE");

                                                    }}
                                                    className={(this.state.creatives.filter((item) => {
                                                        return item.selected && (item.type == "image" || item.type == "video")
                                                    }).length > 0 ? "cursor-pointer bg-green-600 hover:bg-green-700 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    <span>Create</span>
                                                    <PlusCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>

                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state.creatives.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length === 1) {
                                                            this.setState({
                                                                open_cropper: true
                                                            })
                                                        }
                                                    }}
                                                    className={(this.state.creatives.filter((item) => {
                                                        return item.selected && (item.type == "image" || item.type == "video")
                                                    }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    <span>Crop</span>
                                                    <CursorClickIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            remove: { name: "123456", multiple: true }
                                                        })
                                                    }}
                                                    className={(this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    <span>Remove</span>
                                                    <TrashIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        </div>
                                    }


                                    <div className='flex w-ful mt-4'>
                                        <div className='flex flex-1'>
                                        </div>
                                        <div id="seach" className='w-24 flex transition-all duration-700 rounded-md mr-2 relative'>
                                            <input
                                                onBlur={() => {
                                                    document.getElementById('seach').classList.remove('w-120');
                                                    document.getElementById('seach').classList.add('w-24');
                                                }}
                                                onFocus={() => {
                                                    document.getElementById('seach').classList.remove('w-24');
                                                    document.getElementById('seach').classList.add('w-120');
                                                }}
                                                autocomplete="off"
                                                className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                                placeholder={"Search"}
                                                value={this.state.seach_value}
                                                onChange={async (event) => {
                                                    await this.promisedSetState({
                                                        seach_value: event.target.value
                                                    })

                                                }}
                                            />
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className='flex'>

                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    tab_view: "grid"
                                                })
                                            }} className={(this.state.tab_view !== "grid" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5  mr-2 px-2 "}>
                                                <ViewGridIcon className=" h-5 w-5 " />
                                            </div>
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    tab_view: "list"
                                                })
                                            }} className={(this.state.tab_view !== "list" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5 px-2 "}>
                                                <MenuIcon className=" h-5 w-5 " />
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" grid grid-cols-6 w-full pb-6 mt-2 gap-0 bg-white rounded-md shadow">
                                        {
                                            ["facebook", "linkedin", "tiktok", "twitter", "snapchat"].filter((item) => { return this.state.channels[item] }).map((channel) => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            this.state.ad_template.filter((item) => {

                                                                return ((item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel") && item.allowed_channels && item.allowed_channels[channel])

                                                            }).filter((item) => {
                                                                if (this.state.seach_value !== "" && item.name_by_channels) {
                                                                    if (item.name_by_channels[channel] && item.name_by_channels[channel] !== "") {
                                                                        //return true
                                                                        if (item.name_by_channels[channel].toLowerCase().includes(this.state.seach_value.toLowerCase())) {
                                                                            return true
                                                                        } else return false
                                                                    } else return false

                                                                    //return item.name_by_channels[channel].includes(this.state.seach_value);
                                                                } else {
                                                                    return true
                                                                }
                                                            }).length > 0 &&
                                                            <>
                                                                <div onClick={async () => {
                                                                    this.state.open_creatives[channel] = !this.state.open_creatives[channel];
                                                                    await this.promisedSetState({
                                                                        open_creatives: this.state.open_creatives
                                                                    })
                                                                }} className={" text-xl  pt-5 px-4 pb-5 flex flex-row items-center font-medium col-span-6 border-b-2 "}>
                                                                    <div className='flex flex-1'>
                                                                        <div className={"bg-" + (channel) + "-500" + " h-8 w-8 rounded-full flex justify-center items-center mr-3"}>
                                                                            {
                                                                                channel === 'google' &&
                                                                                <img className="w-4"
                                                                                    alt=""
                                                                                    src={require('../assets/images/google_icon.png')} />
                                                                            }
                                                                            {
                                                                                channel === 'facebook' &&
                                                                                <img className="w-2"
                                                                                    alt=""
                                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'linkedin' &&
                                                                                <img className="w-4"
                                                                                    alt=""
                                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'twitter' &&
                                                                                <img className="w-4"
                                                                                    alt=""
                                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                                            }
                                                                            {
                                                                                channel === 'tiktok' &&
                                                                                <img className="w-4"
                                                                                    alt=""
                                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                                            }
                                                                            {
                                                                                channel === 'snapchat' &&
                                                                                <img className="w-4"
                                                                                    alt=""
                                                                                    src={require('../assets/images/snapchat.svg')} />
                                                                            }
                                                                        </div>
                                                                        {channel}
                                                                    </div>
                                                                    <div className='flex justify-center items-center'>
                                                                        {
                                                                            !this.state.open_creatives[channel] &&
                                                                            <ChevronDownIcon className="w-5 h-5 " />
                                                                        }
                                                                        {
                                                                            this.state.open_creatives[channel] &&
                                                                            <ChevronUpIcon className="w-5 h-5 " />
                                                                        }
                                                                    </div>
                                                                </div>


                                                                <SlideDown

                                                                    className={'col-span-6 w-full transform max-h-60 duration-500 overflow-y-scroll px-4 overflow-hidden border-b-2 pb-2 relative ' + (this.state.open_creatives[channel] ? "h-full " : "h-0 ") + (this.state.tab_view === "list" ? "bg-white rounded-md" : " grid grid-cols-12 gap-4")}
                                                                    closed={!this.state.open_creatives[channel]}
                                                                >
                                                                    {
                                                                        this.state.tab_view === "list" &&
                                                                        <div className='flex flex-row w-full items-center bg-white sticky top-0 z-55'>
                                                                            <div className='mr-8'>
                                                                                <div className='h-10 w-10'>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex flex-1'>
                                                                                <div className='grid grid-cols-8 w-full text-sm font-semibold'>
                                                                                    <div className='col-span-2'>
                                                                                        Name
                                                                                    </div>
                                                                                    <div className='col-span-2'>
                                                                                        Headline
                                                                                    </div>
                                                                                    <div className='col-span-2'>
                                                                                        Body
                                                                                    </div>
                                                                                    <div className='col-span-1'>
                                                                                        Type
                                                                                    </div>
                                                                                    <div className='col-span-1'>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                    {
                                                                        this.state.ad_template.filter((item) => {

                                                                            return ((item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel") && item.allowed_channels && item.allowed_channels[channel])

                                                                        }).filter((item) => {
                                                                            if (this.state.seach_value !== "" && item.name_by_channels) {
                                                                                if (item.name_by_channels[channel] && item.name_by_channels[channel] !== "") {
                                                                                    //return true
                                                                                    if (item.name_by_channels[channel].toLowerCase().includes(this.state.seach_value.toLowerCase())) {
                                                                                        return true
                                                                                    } else return false
                                                                                } else return false

                                                                                //return item.name_by_channels[channel].includes(this.state.seach_value);
                                                                            } else {
                                                                                return true
                                                                            }
                                                                        }).filter((item) => { return item }).map((file) => {
                                                                            if (this.state.tab_view === "list") {
                                                                                return (
                                                                                    <Fragment>
                                                                                        <div className='flex flex-row w-full items-center mt-2 pt-1 border-t'>
                                                                                            <div className='mr-8'>
                                                                                                <div className='h-10 w-10'>
                                                                                                    {
                                                                                                        file.type === "image" && !file.ad_type &&
                                                                                                        <div className="object-cover w-full h-full rounded-md inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                                        </div>
                                                                                                    }

                                                                                                    {
                                                                                                        file.ad_type && file.ad_type === "collection_ad" &&
                                                                                                        //<div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                        <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                            <PuzzleIcon className=" h-5 w-5" />
                                                                                                        </div>
                                                                                                    }

                                                                                                    {
                                                                                                        file.type === "video" &&
                                                                                                        <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-full h-full inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                            <source src={file.url} type="video/mp4" />
                                                                                                        </video>
                                                                                                    }
                                                                                                    {
                                                                                                        file.type === "carousel" &&
                                                                                                        //<div className="relative cursor-pointer w-full overflow-hidden rounded-md flex justify-center items-center ">
                                                                                                        <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                            <CollectionIcon className=" h-5 w-5" />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        file.type === "merge" &&
                                                                                                        //<div className="relative cursor-pointer w-full overflow-hidden rounded-md flex justify-center items-center ">
                                                                                                        <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                            <TemplateIcon className="h-5 w-5 " />
                                                                                                        </div>
                                                                                                    }



                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='flex flex-1'>
                                                                                                <div className='grid grid-cols-8 w-full text-sm items-center mt-2'>
                                                                                                    <div className='col-span-2 truncate pr-1'>
                                                                                                        {(file.name_by_channels && file.name_by_channels[channel] && file.name_by_channels[channel] !== "" ? file.name_by_channels[channel] : "-")}
                                                                                                    </div>
                                                                                                    <div className='col-span-2 truncate px-1'>
                                                                                                        {file.headline_by_channels && file.headline_by_channels[channel] && file.headline_by_channels[channel] !== "" ? (file.headline_by_channels[channel].length > 34 ? file.headline_by_channels[channel].substring(0, 34) + "..." : file.headline_by_channels[channel]) : (file.headline ? (file.headline.length > 34 ? file.headline.substring(0, 34) + "..." : file.headline) : (file.type === "merge" ? (file.headlines[0].value !== "" ? file.headlines[0].value : "-") : "-"))}
                                                                                                    </div>
                                                                                                    <div className='col-span-2 truncate px-1'>
                                                                                                        {file.body_by_channels && file.body_by_channels[channel] && file.body_by_channels[channel] !== "" ? (file.body_by_channels[channel].length > 125 ? file.body_by_channels[channel].substring(0, 125) + "..." : file.body_by_channels[channel]) : (file.body ? (file.body.length > 125 ? file.body.substring(0, 125) + "..." : file.body) : (file.type === "merge" ? (file.bodies[0].value !== "" ? file.bodies[0].value : "-") : "-"))}
                                                                                                    </div>
                                                                                                    <div className='col-span-1 px-1'>
                                                                                                        {
                                                                                                            ((file.selected_lead && file.selected_lead.id === 0) || (!file.selected_lead)) && !file.instantExperience &&
                                                                                                            <div className="text-sm">
                                                                                                                {
                                                                                                                    //channel === "snapchat" && file.snapchat_adType && file.snapchat_adType.value ? file.snapchat_adType.name : (file.type.charAt(0).toUpperCase() + file.type.slice(1))}
                                                                                                                }
                                                                                                                {(file.type.charAt(0).toUpperCase() + file.type.slice(1))}
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.selected_lead && file.selected_lead.id !== 0 &&
                                                                                                            <div className="text-sm ">
                                                                                                                <div className="flex justify-items-start items-center">
                                                                                                                    <ClipboardListIcon className="h-4 w-4 mr-1" />{this.truncText(file.selected_lead.name, 12)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.instantExperience &&
                                                                                                            <div className="text-sm ">
                                                                                                                <div className="flex justify-items-start items-center">
                                                                                                                    <PuzzleIcon className="h-4 w-4 mr-1" /> Collection Ad
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='col-span-1 pl-1'>
                                                                                                        <div className='flex'>
                                                                                                            {
                                                                                                                file.allowed_channels &&
                                                                                                                <div className="flex">
                                                                                                                    <button
                                                                                                                        onClick={() => {
                                                                                                                            file.selected = true;
                                                                                                                            file.is_post = file.type === "posts" ? true : false;
                                                                                                                            this.promisedSetState({
                                                                                                                                ad_template: this.state.ad_template
                                                                                                                            });

                                                                                                                            this.promisedSetState({
                                                                                                                                open: true,
                                                                                                                                //collection: (file.ad_type === "collection_ad" ? true : false),
                                                                                                                            });
                                                                                                                        }}
                                                                                                                        className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow w-full rounded-md flex relative justify-center py-2 px-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                                    >
                                                                                                                        <PencilAltIcon className=" h-5 w-5" />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            }
                                                                                                            <div className="flex ml-2">
                                                                                                                <button
                                                                                                                    onClick={async () => {
                                                                                                                        this.setState({
                                                                                                                            remove: { name: "123456", id: file.internal_id, adTemplate: true }
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    className={"cursor-pointer bg-red-500 hover:bg-red-600 text-white shadow w-full flex relative justify-center py-2 px-3  rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                                >
                                                                                                                    <TrashIcon className="h-5 w-5" />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                )
                                                                            }
                                                                            if (this.state.tab_view !== "list") {
                                                                                return (
                                                                                    <Fragment>
                                                                                        {
                                                                                            <div
                                                                                                onMouseLeave={async () => {
                                                                                                    file.show_remove = false;
                                                                                                    await this.promisedSetState({
                                                                                                        ad_template: this.state.ad_template
                                                                                                    })
                                                                                                }}
                                                                                                onMouseOver={async () => {
                                                                                                    file.show_remove = true;
                                                                                                    await this.promisedSetState({
                                                                                                        ad_template: this.state.ad_template
                                                                                                    })
                                                                                                }}
                                                                                                className={(file.selected ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " col-span-4 rounded-md px-2 mt-2 flex flex-col relative"}>
                                                                                                <div className='flex flex-1'>
                                                                                                    <div className={"relative cursor-pointer flex rounded-md " + ((file.type === "video" || file.type === "image") ? "" : " border-1.5  border-gray-700")}>
                                                                                                        {
                                                                                                            file.type === "image" && !file.ad_type &&
                                                                                                            <div className="object-cover w-24 h-24 rounded-md inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.ad_type && file.ad_type === "collection_ad" &&
                                                                                                            <div className=" w-24 h-24 inset-0 pointer-events-none rounded-md border-1.5 border-gray-700">
                                                                                                                <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                                    {
                                                                                                                        file.instantExperience.id !== 0 && file.instantExperience.elements && file.instantExperience.elements.product_set &&
                                                                                                                        file.instantExperience.elements.product_set.map((item, index) => {
                                                                                                                            return (
                                                                                                                                <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                    <img key={index} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                    {
                                                                                                                        file.instantExperience.id !== 0 && file.instantExperience.elements && file.instantExperience.elements.carousel &&
                                                                                                                        file.instantExperience.elements.carousel.map((item, index) => {
                                                                                                                            return (
                                                                                                                                <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                    <img key={index} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                    {
                                                                                                                        file.instantExperience.id === 0 && !file.instantExperience.elements &&
                                                                                                                        <Fragment>
                                                                                                                            <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                                <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                            </div>
                                                                                                                            <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                                <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                            </div>
                                                                                                                            <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                                <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                            </div>
                                                                                                                            <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                                <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                            </div>
                                                                                                                        </Fragment>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.type === "video" &&
                                                                                                            <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-24 h-24 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                <source src={file.url} type="video/mp4" />
                                                                                                            </video>
                                                                                                        }
                                                                                                        {
                                                                                                            (file.type === "carousel" || file.type === "merge") &&
                                                                                                            Array.isArray(file.files) &&
                                                                                                            <div className=" w-24 h-24 inset-0 pointer-events-none">
                                                                                                                <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                                    {
                                                                                                                        file.files.map((item) => {
                                                                                                                            if (item.type === "image") {
                                                                                                                                return (
                                                                                                                                    <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                        <div className="object-cover w-full h-full p-2 rounded-md absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            } else if (item.type === "video") {
                                                                                                                                return (
                                                                                                                                    <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                        <video key={item.url} autoPlay={false} muted={true} controls={true} className="w-full h-full p-2 rounded-md absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                                            <source src={item.url} type="video/mp4" />
                                                                                                                                        </video>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            } else {
                                                                                                                                return <div></div>
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="ml-2 flex flex-col truncate">

                                                                                                        <div className="text-sm font-bold truncate ">
                                                                                                            Name: {(file.name_by_channels && file.name_by_channels[channel] && file.name_by_channels[channel] !== "" ? file.name_by_channels[channel] : "")}
                                                                                                        </div>
                                                                                                        {
                                                                                                            file.body_by_channels && file.body_by_channels[channel] && channel !== "snapchat" &&
                                                                                                            <div className="text-sm truncate">
                                                                                                                Body: {file.body_by_channels && file.body_by_channels[channel] && file.body_by_channels[channel] !== "" ? (file.body_by_channels[channel].length > 125 ? file.body_by_channels[channel].substring(0, 125) + "..." : file.body_by_channels[channel]) : (file.body ? (file.body.length > 125 ? file.body.substring(0, 125) + "..." : file.body) : (file.type === "merge" ? file.bodies[0].value : ""))}
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.headline_by_channels && file.headline_by_channels[channel] &&
                                                                                                            <>
                                                                                                                <div className="text-sm truncate">
                                                                                                                    Headline: {file.headline_by_channels && file.headline_by_channels[channel] && file.headline_by_channels[channel] !== "" ? (file.headline_by_channels[channel].length > 34 ? file.headline_by_channels[channel].substring(0, 34) + "..." : file.headline_by_channels[channel]) : (file.headline ? (file.headline.length > 34 ? file.headline.substring(0, 34) + "..." : file.headline) : (file.type === "merge" ? file.headlines[0].value : ""))}
                                                                                                                </div>

                                                                                                            </>

                                                                                                        }
                                                                                                        {
                                                                                                            ((file.selected_lead && file.selected_lead.id === 0) || (!file.selected_lead)) && !file.instantExperience &&
                                                                                                            <div className="text-sm">
                                                                                                                Type: {file.snapchat_adType && file.snapchat_adType.value ? file.snapchat_adType.name : "Single Image or Video"}
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.selected_lead && file.selected_lead.id !== 0 &&
                                                                                                            <div className="text-sm ">
                                                                                                                <div className="flex justify-items-start items-center">
                                                                                                                    Lead Form: <ClipboardListIcon className="h-4 w-4 mr-1" /> {file.selected_lead.name}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            file.instantExperience &&
                                                                                                            <div className="text-sm ">
                                                                                                                <div className="flex justify-items-start items-center">
                                                                                                                    Type: <PuzzleIcon className="h-4 w-4 mr-1" /> Collection Ad
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    file.show_remove &&
                                                                                                    <div className="flex flex-col absolute bottom-0 w-full h-full pr-2 mt-2 rounded-md">
                                                                                                        <div className='flex flex-1 bg-purple-900 opacity-25 rounded-md rounded-b-none'></div>
                                                                                                        <div className='flex'>
                                                                                                            {
                                                                                                                file.allowed_channels &&
                                                                                                                <div className="flex flex-1">
                                                                                                                    <button
                                                                                                                        onClick={() => {
                                                                                                                            file.selected = true;
                                                                                                                            file.is_post = file.type === "posts" ? true : false;
                                                                                                                            this.promisedSetState({
                                                                                                                                ad_template: this.state.ad_template
                                                                                                                            });
                                                                                                                            if (channel === "linkedin" && file.type === "carousel") {
                                                                                                                                this.promisedSetState({
                                                                                                                                    //selected_channels: ["linkedin"]
                                                                                                                                });
                                                                                                                            }
                                                                                                                            this.promisedSetState({
                                                                                                                                open: true,
                                                                                                                                //collection: (file.ad_type === "collection_ad" ? true : false),
                                                                                                                            });
                                                                                                                        }}
                                                                                                                        className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow w-full rounded-md rounded-t-none rounded-r-none flex relative justify-center py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                                    >
                                                                                                                        <span>Edit</span>
                                                                                                                        <PencilAltIcon className="ml-2 h-5 w-5" />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            }
                                                                                                            <div className="flex flex-1 ">
                                                                                                                <button
                                                                                                                    onClick={async () => {
                                                                                                                        /*file.selected = true;
                                                                                                                        await this.promisedSetState({
                                                                                                                            creatives: this.state.creatives
                                                                                                                        });
                                                                                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                                                                                            return !item.selected;
                                                                                                                        });
                                                                                                                        await this.promisedSetState({
                                                                                                                            creatives: this.state.creatives
                                                                                                                        });
                                                                                                                        await this.form.set();
                                                                                                                        this.props.setSteps();*/

                                                                                                                    }}
                                                                                                                    className={"cursor-pointer bg-red-500 hover:bg-red-600 text-white shadow w-full flex relative justify-center py-2 px-4  rounded-md rounded-t-none rounded-l-none text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                                >
                                                                                                                    <span>Remove</span>
                                                                                                                    <TrashIcon className="ml-2 h-5 w-5" />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    !file.show_remove &&
                                                                                                    <div className="flex flex-row">
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </Fragment>
                                                                                )
                                                                            }
                                                                        })

                                                                    }
                                                                </SlideDown>
                                                            </>
                                                        }
                                                    </Fragment>
                                                )
                                            })

                                        }
                                    </div>


                                    {
                                        false &&
                                        <ul role="list" className="grid grid-cols-5 gap-4">
                                            {
                                                this.state.creatives.map((file) => (
                                                    <li key={file.source} className={(file.selected ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " relative p-6 pb-5 border-1.5 shadow-sm rounded-lg cursor-pointer"}>
                                                        <div onClick={() => {
                                                            this.setState({
                                                                remove: file
                                                            })
                                                        }} className="absolute -mt-3 -mr-3 z-10 cursor-pointer top-0 right-0 h-8 w-8 bg-red-500 border-red-500 border-1.5 rounded-full flex justify-center items-center">
                                                            <XIcon className="text-white font-medium h-5 w-5" />
                                                        </div>
                                                        <div onClick={() => {
                                                            if (window.event && window.event.shiftKey) {
                                                                file.selected = !file.selected;
                                                                this.promisedSetState({
                                                                    creatives: this.state.creatives
                                                                });
                                                            } else {
                                                                this.state.creatives = this.state.creatives.map((item) => {
                                                                    item.selected = false;
                                                                    return item;
                                                                });
                                                                file.selected = true;
                                                                this.promisedSetState({
                                                                    creatives: this.state.creatives,
                                                                    open: true
                                                                });
                                                            }
                                                        }} style={{ paddingTop: '100%' }} className={'relative cursor-pointer block w-full rounded-lg overflow-hidden bg-custom-input'}>
                                                            {
                                                                file.type === "image" &&
                                                                <img src={file.url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none" />
                                                            }
                                                            {
                                                                file.type === "video" &&
                                                                <video poster={file.thumbnail} autoPlay={true} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none">
                                                                    <source src={file.url} type="video/mp4" />
                                                                </video>
                                                            }
                                                            {
                                                                file.loading &&
                                                                <div className="w-full h-full absolute inset-0 flex justify-center items-center z-20 rounded-lg">
                                                                    <div style={{ borderTopColor: "transparent" }} class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={(file.loading ? "opacity-0" : "") + " mt-2 text-sm font-medium text-gray-900"}>{file.name}</div>
                                                        <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>{this.renders.prettySize(file.size)}</div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }

            </div>
        )
    }
}

export default OrderCreatives;