import React, { Component, Fragment } from 'react';
import './style.css';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Campaign from "../../components/campaign";
import { userRegister } from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import { Doughnut } from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import OptimizeActive from "../../components/optimize-active";
import { calls } from "./calls";
import OptimizeDisabled from "../../components/optimize-disabled";
import OptimizeSocial from "../../components/optimize-social";
import OptimizeSearch from "../../components/optimize-search";

import DropDown from "../../modules/dropdown";
import SearchtermList from "../../modules/searchtermlist";
import FeatherIcon from "feather-icons-react";
import OptimizeSearchKeywordsAsset from "../../components/optimize-search-keywords-asset";
import OptimizeSearchKeywordsAdd from "../../components/optimize-search-keywords-add";
import NegativeKeywordList from "../../modules/negativekeywordlist";
import Input from "../../components/input";
import ReactTooltip from "react-tooltip";
import CSVReader from "react-csv-reader";
import Loader from "../../components/loader";
import CsvDownload from 'react-json-to-csv'
import cx from "classnames";
import CampaignsList from "../../modules/campaignslist";
import SelectClientAndCampaign from '../../modules/selectClientAndCampaign';
import AddKeyword from '../../modules/addKeyword';

const fuzzysort = require('fuzzysort');
var moment = require('moment');

class UserOptimizeAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            client: {},
            loading_clients: true,
            loading_campaigns: false,
            types: [],
            loading_types: false,
            campaigns: [],
            ads: [],
            adsets: [],
            queries: [],
            type: {},
            loading: true,
            loading_campaign: false,
            open: true,
            social_channels: [],
            search_channels: [],
            order: "desc",
            sort: "startDate",


            submenu: "Negative keywords",
            closed: true,
            client: null,
            campaigns: [],
            adgroups: [],
            negative_keywords: [],
            keywords: []
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    functions = {};

    renders = {};

    render() {
        return (
            <section className="w-full pt-8 pb-8">

                {/*
                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! SLIDEOUT !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                */}

                <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                    style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                    <div className="absolute inset-0 overflow-hidden">
                        <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                            <div
                                className={`relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}>
                                <div className="absolute right-100 pt-5 pr-5 flex">
                                    <button aria-label="Close panel"
                                        className="bg-red-500 text-white border-2 border-white font-bold text-xs flex items-center justify-center rounded h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                        onClick={() => {
                                            this.setState({
                                                closed: !this.state.closed
                                            })
                                        }}
                                    >
                                        <FeatherIcon className="stroke-current" size={16} icon="x" />
                                    </button>
                                </div>
                                <div className={"flex flex-row-reverse bg-white h-full shadow-xl"}>
                                    <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                        <header className="px-6 mb-6 py-6 bg-gray-100">
                                            <h2 className="text-lg leading-7 text-gray-900">
                                                Optimize campaign
                                            </h2>
                                        </header>
                                        <SelectClientAndCampaign
                                            ref="slideout"
                                            history={this.props.history}
                                            onChange={(client, campaigns) => {
                                                console.log(client, campaigns);
                                                this.setState({
                                                    client: client,
                                                    campaigns: campaigns,
                                                    closed: true
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="">
                    <div className="">
                        {
                            <div className="flex items-center justify-center">
                                <div className="flex flex-1">
                                    <div>
                                        <h3 className="mb-0 font-bold">
                                            Optimize campaign
                                        </h3>
                                        {
                                            !this.state.campaign &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                Select campaign
                                            </p>
                                        }
                                        {
                                            this.state.campaign &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                You have selected <span
                                                    className="font-bold ml-1 mr-1">campaign name</span> from client
                                                <span
                                                    className="font-bold ml-1 mr-1">cleint name</span>
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div onClick={(e) => {
                                    this.setState({
                                        closed: false
                                    }, () => {
                                        if (!this.state.closed) {
                                            this.refs.slideout.functions.init();
                                        }
                                    })
                                }} className="btn inline-block btn-primary">Select campaign
                                </div>
                            </div>
                        }


                        {/*
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! LIST !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        */}
                        {
                            <div className="bg-white rounded-md mt-4 flex-col flex  overflow-x-auto border-b border-gray-200 sm:rounded-lg shadow-lg">
                                <div style={{ backgroundColor: "#fcfcfc", height: "52px" }} className="px-4 rounded-t-lg border-b-2 relative">
                                    <div>
                                        <div className="navigation pl-2">
                                            <nav role="navigation" aria-label="Social sub navigation">
                                                <ul className="flex flex-row items-end">
                                                    {["Negative keywords", "Search terms", "Adgroups", "Keywords"].map((key, j) => {
                                                        return (
                                                            <li key={j}
                                                                className={((this.state.client &&
                                                                    Array.isArray(this.state.campaigns) &&
                                                                    this.state.campaigns.length > 0) ? "cursor-pointer " : "cursor-not-allowed ") + " capitalize font-bold flex flex-row text-xs py-4 px-4 border-b-2 " + (this.state.submenu == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'text-gray-500')}
                                                                onClick={() => {
                                                                    if (this.state.client &&
                                                                        Array.isArray(this.state.campaigns) &&
                                                                        this.state.campaigns.length > 0) {
                                                                        this.setState({
                                                                            submenu: key
                                                                        });
                                                                    }
                                                                }}>
                                                                {key}
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                    <li className="flex-grow border-b-2"></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                {/*
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! NOTHING SELECTED !!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                */}
                                {
                                    !this.state.client &&
                                    Array.isArray(this.state.campaigns) &&
                                    this.state.campaigns.length < 1 &&
                                    <div className="w-full h-48 flex justify-center items-center align-middle font-bold">
                                        <div className="flex flex-col justify-center items-center align-middle">
                                            <div className="mb-2">Select campaign to get started</div>
                                            <div className="flex justify-center items-center align-middle">
                                                <div onClick={(e) => {
                                                    this.setState({
                                                        closed: false
                                                    }, () => {
                                                        if (!this.state.closed) {
                                                            this.refs.slideout.functions.init();
                                                        }
                                                    })
                                                }} className="btn inline-block btn-primary">Get started
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/*
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! SEARCH TERMS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                */}
                                {
                                    this.state.client &&
                                    Array.isArray(this.state.campaigns) &&
                                    this.state.campaigns.length > 0 &&
                                    this.state.submenu === "Search terms" &&
                                    <SearchTerms
                                        queries={this.state.queries}
                                        keywords={this.state.negative_keywords}
                                        client={this.state.client}
                                        campaigns={this.state.campaigns}
                                        setSearchTerms={(value) => {
                                            this.setState({
                                                queries: value
                                            })
                                        }}
                                        setKeyword={(keyword) => {
                                            console.log(keyword);
                                            this.state.negative_keywords.unshift(keyword);
                                            this.setState({
                                                negative_keywords: this.state.negative_keywords
                                            })
                                        }}
                                    />
                                }
                                {/*
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! NEGATIVE KEYWORDS !!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                */}
                                {
                                    this.state.client &&
                                    Array.isArray(this.state.campaigns) &&
                                    this.state.campaigns.length > 0 &&
                                    this.state.submenu === "Negative keywords" &&
                                    <NegativeKeywords
                                        keywords={this.state.negative_keywords}
                                        client={this.state.client}
                                        campaigns={this.state.campaigns}
                                        setKeywords={(value) => {
                                            this.setState({
                                                negative_keywords: value
                                            })
                                        }}
                                    />
                                }
                                {/*
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ADGROUPS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                */}
                                {
                                    this.state.client &&
                                    Array.isArray(this.state.campaigns) &&
                                    this.state.campaigns.length > 0 &&
                                    this.state.submenu === "Adgroups" &&
                                    <Adgroups
                                        adgroups={this.state.adgroups}
                                        client={this.state.client}
                                        campaigns={this.state.campaigns}
                                        setAdgroups={(value) => {
                                            this.setState({
                                                adgroups: value
                                            })
                                        }}
                                        setKeyword={(keyword) => {
                                            console.log(keyword);
                                            this.state.keywords.unshift(keyword);
                                            this.setState({
                                                keywords: this.state.keywords
                                            })
                                        }}
                                    />
                                }
                                {/*
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! KEYWORDS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                */}
                                {
                                    this.state.client &&
                                    Array.isArray(this.state.campaigns) &&
                                    this.state.campaigns.length > 0 &&
                                    this.state.submenu === "Keywords" &&
                                    <Keywords
                                        keywords={this.state.keywords}
                                        client={this.state.client}
                                        campaigns={this.state.campaigns}
                                        setKeywords={(value) => {
                                            this.setState({
                                                keywords: value
                                            })
                                        }}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

class SearchTerms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queries: [],
            keywords: [],

            client: {},
            campaigns: [],

            sort: 'conversions',
            order: 'desc',
            loading: false,
            index: 10,
            pagesize: 10,
            search: "",
            closed: true
        };
    };

    componentDidMount() {
        this.setState({
            campaigns: Array.isArray(this.props.campaigns) ? this.props.campaigns : [],
            client: this.props.client ? this.props.client : {},
            queries: this.props.queries,
            keywords: this.props.keywords
        }, () => {
            if (this.state.client.id && this.state.campaigns.length > 0 && Array.isArray(this.state.queries) && this.state.queries.length < 1) {
                this.functions.getSearchterms();
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaigns: Array.isArray(nextProps.campaigns) ? nextProps.campaigns : [],
            client: nextProps.client ? nextProps.client : {},
            queries: nextProps.queries,
            keywords: nextProps.keywords
        })
    }

    init = {};

    functions = {
        getSearchterms: () => {
            this.setState({
                loading: true,
                queries: [],
            }, () => {
                calls.getSearchterms(this.state.client.id, this.state.campaigns.map((item) => { return item.id }).join(","), this.state.sort, this.state.order).then((response) => {
                    this.setState({
                        queries: response.data.map((item) => {
                            item.campaign = item.external_id;
                            return item;
                        }),
                        loading: false
                    }, () => {
                        this.props.setSearchTerms(this.state.queries);
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    });
                });
            })
        },
        downloadCsv: () => {
            try {
                let filename = this.state.campaign.name + " (Search terms).csv";
                let arrayOfJson = this.state.queries.filter((item) => {
                    return this.renders.negativeVsSearchTerm(item);
                }).filter((item) => {
                    return item.query && item.query.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                }).map((item) => {
                    return {
                        "Channel": item.channel,
                        "Campaign ID": item.external_id,
                        "Adgroup ID": item.adgroup_id,
                        "Keyword": item.keyword,
                        "Keyword Type": item.type,
                        "Search Term": item.query,
                        "Click": item.click,
                        "Impressions": item.impressions,
                        "CTR": item.ctr,
                        "CPC": item.cpc,
                        "Spend": item.spend,
                        "Currency": item.currency,
                    }
                });
                console.log(arrayOfJson);
                const replacer = (key, value) => value === null ? "" : value // specify how you want to handle null values here
                const header = ["Channel", "Campaign ID", "Adgroup ID", "Keyword", "Keyword Type", "Search Term", "Click", "Impressions", "CTR", "CPC", "Spend", "Currency"];
                let csv = arrayOfJson.map(row => header.map(fieldName =>
                    JSON.stringify(row[fieldName], replacer)).join(','))
                csv.unshift(header.join(','))
                csv = csv.join('\r\n')

                // Create link and download
                var link = document.createElement('a');
                link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.log(error);
            }
        }
    };

    renders = {
        negativeVsSearchTerm: (item) => {
            try {
                return this.state.keywords.filter((keyword) => {
                    if (keyword.type === "exact" && keyword.text.toLowerCase() === item.query.toLowerCase()) {
                        return true;
                    } else if (keyword.type === "phrase" && item.query.toLowerCase().indexOf(keyword.text.toLowerCase()) !== -1) {
                        return true
                    } else if (keyword.type === "broad") {
                        let exist = false;
                        let tokenize = keyword.text.split(" ");
                        if (Array.isArray(tokenize)) {
                            tokenize.map((token) => {
                                if (token.toLowerCase() === item.query.toLowerCase()) {
                                    exist = true;
                                }
                            })
                        }
                        return exist
                    } else {
                        return false;
                    }
                }).length < 1
            } catch (error) {
                return true;
            }
        },
        filter: () => {
            let options = this.state.queries.filter((item) => {
                return this.renders.negativeVsSearchTerm(item);
            });
            let keywords = [];
            if (this.state.search && this.state.search !== "") {
                let clients = fuzzysort.go(this.state.search, options.map((item) => { return item.query }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].query.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        },
    };

    render() {
        return (
            <div>
                {
                    <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                        style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                        <div className="absolute inset-0 overflow-hidden">
                            <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                                <div
                                    className={`relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}>
                                    <div className="absolute right-100 pr-6 pt-6 flex">
                                        <button aria-label="Close panel"
                                            className="bg-red-500 text-white border-2 border-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                            onClick={() => {
                                                this.setState({
                                                    closed: !this.state.closed
                                                })
                                            }}
                                        >
                                            <FeatherIcon className="stroke-current" size={16} icon="x" />
                                        </button>
                                    </div>
                                    <div className={"flex flex-row-reverse bg-white h-full shadow-xl"}>
                                        <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                            <header className="p-6 bg-gray-100">
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Add negative keyword
                                                </h2>
                                            </header>
                                            <AddKeyword
                                                ref="slideout"
                                                negative={true}
                                                campaigns={this.state.campaigns}
                                                client={this.state.client}
                                                onChange={(keywords) => {
                                                    this.setState({
                                                        closed: true
                                                    }, () => {
                                                        if (Array.isArray(keywords)) {
                                                            console.log(keywords);
                                                            keywords.map((item) => {
                                                                this.props.setKeyword(item);
                                                            })
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="flex flex-1 justify-end">
                        <div className="py-4 px-6 flex flex-row w-full">
                            <div className="w-64 mr-5">
                                <div className="text-xs font-bold mb-1">
                                    Search
                                </div>
                                <input
                                    autocomplete="off"
                                    onChange={(event) => {
                                        this.setState({
                                            search: event.target.value
                                        })
                                    }}
                                    value={this.state.search}
                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                />
                            </div>
                        </div>
                        <button
                            onClick={() => {
                                this.functions.downloadCsv();
                            }}
                            className="py-2 pt-0 pl-2 text-xs text-purple-500 hover:underline inline-flex flex-row items-center relative">
                            <FeatherIcon className="stroke-current mr-2" size={15} icon="download" />
                            <span>Export (.csv)</span>
                        </button>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="border-b w-full" />
                }
                {
                    this.state.loading &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">Loading Search Terms ...</div>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="overflow-scroll">
                        <table className="min-w-full divide-y divide-gray-200 bg-white">
                            <thead>
                                <tr>
                                    <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions{false && <div className="sorting-arrows ml-2"></div>}
                                        </div>
                                    </th>
                                    {
                                        [
                                            { title: "Campaign", value: "campaign" },
                                            { title: "Query", value: "query" },
                                            { title: "Type", value: "type" },
                                            { title: "Keyword", value: "keyword" },
                                            { title: "Conversions", value: "conversions" },
                                            { title: "Impressions", value: "impressions" },
                                            { title: "Click", value: "click" },
                                            { title: "CTR", value: "ctr" },
                                            { title: "CPC", value: "cpc" },
                                            { title: "Spend", value: "spend" }
                                        ].map((item) => {
                                            return (
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: item.value,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200 text-left text-xs leading-4 px-6 py-3 font-medium uppercase " + (this.state.sort === item.value ? 'text-gray-700 border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        {item.title} <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {this.renders.filter().filter((item, i) => {
                                    return i < this.state.pagesize
                                }).sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            if (a[this.state.sort].value) {
                                                nameA = a[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameA = a[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            if (b[this.state.sort].value) {
                                                nameB = b[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameB = b[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row items-center justify-start">
                                                        {
                                                            <button onClick={() => {
                                                                this.setState({
                                                                    closed: false,
                                                                }, () => {
                                                                    if (!this.state.closed) {
                                                                        this.refs.slideout.functions.init(item.query);
                                                                    }
                                                                })
                                                            }} className="bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Add negative</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="thumbs-down" />
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                                {
                                                    [
                                                        { title: "Campaign", value: "campaign" },
                                                        { title: "Query", value: "query" },
                                                        { title: "Type", value: "type" },
                                                        { title: "Keyword", value: "keyword" },
                                                        { title: "Conversions", value: "conversions" },
                                                        { title: "Impressions", value: "impressions" },
                                                        { title: "Click", value: "click" },
                                                        { title: "CTR", value: "ctr" },
                                                        { title: "CPC", value: "cpc" },
                                                        { title: "Spend", value: "spend" }
                                                    ].map((inner_item) => {
                                                        return (
                                                            <td className={(this.state.sort === inner_item.value ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}>
                                                                <div className="flex flex-row items-center">
                                                                    <div
                                                                        className={"text-sm leading-5 text-gray-900"}>
                                                                        {item[inner_item.value]}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    !this.state.loading && (this.renders.filter().length > this.state.pagesize) &&
                    <div className="mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                        <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                            <div onClick={(e) => {
                                this.setState({
                                    pagesize: this.state.pagesize + 10
                                })
                            }} className="btn btn-primary btn-lg max-w-md flex-1">
                                <div>
                                    Show more ({this.renders.filter().length}/{this.state.pagesize})
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({
                                pagesize: 10000000000
                            })
                        }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                            show all
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.queries.length < 1 &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">No search terms available</div>
                    </div>
                }
            </div>
        )
    }

}

class NegativeKeywords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keywords: [],
            channels: [],
            campaign: {},
            sort: 'text',
            order: 'asc',
            loading: false,
            index: 10,
            pagesize: 10,
            keyword_text: "",
            keyword_channel: {},
            keyword_type: {},
            client: {},
            search: "",
            closed: true
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            campaigns: this.props.campaigns,
            keywords: this.props.keywords,
        }, () => {
            if (this.state.client.id && this.state.campaigns.length > 0 && Array.isArray(this.state.keywords) && this.state.keywords.length < 1) {
                this.functions.get();
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            keywords: nextProps.keywords,
            campaigns: this.props.campaigns,
        })
    }

    init = {};

    functions = {
        get: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    keywords: [],
                }, () => {
                    calls.getNegativeKeywords(this.state.client.id, this.state.campaigns.map((item) => { return item.id }).join(",")).then((response) => {
                        this.setState({
                            keywords: (response.data && Array.isArray(response.data.negative)) ? response.data.negative : [],
                            loading: false
                        }, () => {
                            this.props.setKeywords(this.state.keywords);
                        });
                    }, (error) => {
                        this.setState({
                            loading: false
                        });
                    });
                })
            }
        },
        remove: (item) => {
            if (!item.loading) {
                item.loading = true;
                this.setState({
                    keywords: this.state.keywords
                }, () => {
                    calls.removeKeyword({
                        criterion_id: item.criterion_id,
                        campaign: item.campaign,
                        client: this.props.client.id,
                        negative: true
                    }).then((response) => {
                        this.setState({
                            keywords: this.state.keywords.filter((inner_item) => {
                                return inner_item.criterion_id !== item.criterion_id;
                            })
                        }, () => {
                            this.props.setKeywords(this.state.keywords);
                        })
                    }, (error) => {
                        item.loading = false;
                        this.setState({
                            keywords: this.state.keywords
                        });
                    });
                })
            }
        },
    };

    renders = {
        filter: () => {
            let options = this.state.keywords;
            let keywords = [];
            if (this.state.search && this.state.search !== "") {
                let clients = fuzzysort.go(this.state.search, options.map((item) => { return item.text }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].text.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        },
    };

    render() {
        return (
            <div>
                {
                    <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                        style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                        <div className="absolute inset-0 overflow-hidden">
                            <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                                <div
                                    className={`relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}>
                                    <div className="absolute right-100 pr-6 pt-6 flex">
                                        <button aria-label="Close panel"
                                            className="bg-red-500 text-white border-2 border-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                            onClick={() => {
                                                this.setState({
                                                    closed: !this.state.closed
                                                })
                                            }}
                                        >
                                            <FeatherIcon className="stroke-current" size={16} icon="x" />
                                        </button>
                                    </div>
                                    <div className={"flex flex-row-reverse bg-white h-full shadow-xl"}>
                                        <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                            <header className="p-6 bg-gray-100">
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Add negative keyword
                                                </h2>
                                            </header>
                                            <AddKeyword
                                                ref="slideout"
                                                negative={true}
                                                client={this.state.client}
                                                campaigns={this.state.campaigns}
                                                onChange={(client, campaigns) => {

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="py-4 px-6 flex flex-row w-full">
                        <div className="w-64 mr-5">
                            <div className="text-xs font-bold mb-1">
                                Search
                            </div>
                            <input
                                autocomplete="off"
                                onChange={(event) => {
                                    this.setState({
                                        search: event.target.value
                                    })
                                }}
                                value={this.state.search}
                                className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                            />
                        </div>
                        <div className="flex flex-1 justify-end">
                            <div>
                                <div className="btn inline-block btn-primary" onClick={() => {
                                    this.setState({
                                        closed: false,
                                    }, () => {
                                        if (!this.state.closed) {
                                            this.refs.slideout.functions.init();
                                        }
                                    })
                                }}>
                                    Add keyword
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="border-b w-full" />
                }
                {
                    this.state.loading &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">Loading Negative Keywords ...</div>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="overflow-scroll">
                        <table className="min-w-full divide-y divide-gray-200 bg-white">
                            <thead>
                                <tr>
                                    <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions{false && <div className="sorting-arrows ml-2"></div>}
                                        </div>
                                    </th>
                                    {
                                        [
                                            { title: "Campaign", value: "campaign" },
                                            { title: "Keyword", value: "text" },
                                            { title: "Type", value: "type" },
                                            { title: "Level", value: "level" },
                                        ].map((item) => {
                                            return (
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: item.value,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200 text-left text-xs leading-4 px-6 py-3 font-medium uppercase " + (this.state.sort === item.value ? 'text-gray-700 border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        {item.title} <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {this.renders.filter().filter((item, i) => {
                                    return i < this.state.pagesize
                                }).sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            if (a[this.state.sort].value) {
                                                nameA = a[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameA = a[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            if (b[this.state.sort].value) {
                                                nameB = b[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameB = b[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row items-center justify-start">
                                                        {
                                                            <button onClick={() => {
                                                                this.functions.remove(item)
                                                            }} className="bg-red-200 relative overflow-hidden bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Delete negative</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="trash" />
                                                                {
                                                                    item.loading &&
                                                                    <div className="absolute left-0 bg-red-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                                {
                                                    [
                                                        { title: "Campaign", value: "campaign" },
                                                        { title: "QTextuery", value: "text" },
                                                        { title: "Type", value: "type" },
                                                        { title: "Level", value: "level" },
                                                    ].map((inner_item) => {
                                                        return (
                                                            <td className={(this.state.sort === inner_item.value ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}>
                                                                <div className="flex flex-row items-center">
                                                                    <div
                                                                        className={"text-sm leading-5 text-gray-900"}>
                                                                        {item[inner_item.value]}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    !this.state.loading && (this.renders.filter().length > this.state.pagesize) &&
                    <div className="mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                        <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                            <div onClick={(e) => {
                                this.setState({
                                    pagesize: this.state.pagesize + 10
                                })
                            }} className="btn btn-primary btn-lg max-w-md flex-1">
                                <div>
                                    Show more ({this.renders.filter().length}/{this.state.pagesize})
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({
                                pagesize: 10000000000
                            })
                        }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                            show all
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.keywords.length < 1 &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">No keywords available</div>
                    </div>
                }
            </div>
        )
    }

}

class Adgroups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            adgroups: [],
            campaign: {},
            client: {},
            search: "",
            sort: 'conversions',
            order: 'desc',
            closed: true,
            pagesize: 10,
        };
    };

    componentDidMount() {
        this.setState({
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            client: this.props.client ? this.props.client : {},
        }, () => {
            if (this.state.client.id && this.state.campaigns.length > 0 && this.state.adgroups.length < 1) {
                this.functions.get();
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            client: nextProps.client ? nextProps.client : {}
        })
    }

    functions = {
        get: () => {
            if (Array.isArray(this.state.adgroups) && this.state.adgroups.length < 1) {
                this.setState({
                    loading: true
                }, () => {
                    calls.getAdgroups(
                        123456,
                        "google",
                        this.state.campaigns.map((item) => { return item.id }).join(','),
                        this.state.client.id
                    ).then((response) => {
                        let adgroups = [];
                        if (response.data.channels && response.data.channels.google && Array.isArray(response.data.channels.google.adgroups)) {
                            adgroups = response.data.channels.google.adgroups.filter((item) => {
                                return item.status !== "REMOVED"
                            }).map((item) => {
                                item.status = this.renders.status("google", item.status);
                                item = this.renders.insights(item);
                                return item;
                            })
                        }
                        this.setState({
                            adgroups: adgroups,
                            loading: false
                        }, () => {
                            this.props.setAdgroups(this.state.adgroups);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        status: (item, status) => {
            if (!item.loading_status) {
                item.loading_status = true;
                this.setState({
                    adgroups: this.state.adgroups
                }, () => {
                    calls.updateAdgroup({
                        update_status: true,
                        id: 123456,
                        campaign: item.campaign,
                        client: this.props.client.id,
                        adgroup: item.id,
                        channel: "google",
                        status: status
                    }).then((response) => {
                        item.loading_status = false;
                        item.status = this.renders.status("google", response.data);
                        this.setState({
                            adgroups: this.state.adgroups,
                        }, () => {
                            this.props.setAdgroups(this.state.adgroups);
                        })
                    }, (error) => {
                        item.loading_status = false;
                        this.setState({
                            adgroups: this.state.adgroups
                        }, () => {
                            this.props.setAdgroups(this.state.adgroups);
                        })
                    });
                })
            }
        },
        remove: (item) => {
            if (!item.loading_remove) {
                item.loading_remove = true;
                this.setState({
                    adgroups: this.state.adgroups,
                    remove_modal: false,
                    remove_adgroup: false,
                }, () => {
                    calls.removeAdgroup({
                        id: 123456,
                        campaign: item.campaign,
                        client: this.props.client.id,
                        adgroup: item.id,
                        channel: "google"
                    }).then((response) => {
                        item.loading_remove = false;
                        this.state.adgroups = this.state.adgroups.filter((inner_item) => {
                            return inner_item.id !== item.id;
                        });
                        this.setState({
                            adgroups: this.state.adgroups
                        }, () => {
                            this.props.setAdgroups(this.state.adgroups);
                        })
                    }, (error) => {
                        item.loading_remove = false;
                        this.setState({
                            adgroups: this.state.adgroups
                        }, () => {
                            this.props.setAdgroups(this.state.adgroups);
                        })
                    });
                })
            }
        },
    };

    renders = {
        status: (channel, external_status) => {
            try {
                let status = {};
                if (channel === "google") {
                    if (external_status === "ENABLED") {
                        status = { name: "Active", value: "ENABLED" };
                    } else if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    } else if (external_status === "REMOVED") {
                        status = { name: "Removed", value: "REMOVED" };
                    } else {
                        status = { name: "None", value: "NONE" };
                    }
                }
                return status;
            } catch (e) {
                return { name: "None", value: "NONE" };
            }
        },
        insights: (item) => {
            let basic_metrics = {
                "impressions": { title: "Impressions", value: "impressions", factor: 1 },
                "clicks": { title: "Click", value: "click", factor: 1 },
                "ctr": { title: "CTR", value: "ctr", factor: 100 },
                "average_cpc": { title: "CPC", value: "cpc", factor: 0.000001 },
                "cost_micros": { title: "Spend", value: "spend", factor: 0.000001 },
            };
            if (Array.isArray(item.insights)) {
                item.insights.map((inner_item) => {
                    for (let key in inner_item) {
                        if (item[key] === undefined) {
                            item[key] = 0;
                        }
                        item[key] += +inner_item[key];
                        if (basic_metrics[key]) {
                            item[basic_metrics[key].value] = item[key] * basic_metrics[key].factor;
                        }
                        if (key === "ctr" || key === "average_cpc" || key === "cost_micros") {
                            item[basic_metrics[key].value] = parseFloat(item[basic_metrics[key].value]).toFixed(2);
                        }
                    }
                });
                delete item.insights;
            }
            return item;
        },
        filter: () => {
            let options = this.state.adgroups;
            let keywords = [];
            if (this.state.search && this.state.search !== "") {
                let clients = fuzzysort.go(this.state.search, options.map((item) => { return item.name }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        },
    };

    render() {
        return (
            <div>
                {
                    <SweetAlert
                        show={this.state.remove_modal}
                        title="Remove Adgroup"
                        type="warning"
                        text={"Are you sure ?"}
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton={true}
                        onConfirm={() => {
                            this.functions.remove(this.state.remove_adgroup);
                        }}
                        onCancel={() => {
                            this.setState({ remove_modal: false });
                        }}
                    />
                }
                {
                    <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                        style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                        <div className="absolute inset-0 overflow-hidden">
                            <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                                <div
                                    className={`relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}>
                                    <div className="absolute right-100 pr-6 pt-6 flex">
                                        <button aria-label="Close panel"
                                            className="bg-red-500 text-white border-2 border-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                            onClick={() => {
                                                this.setState({
                                                    closed: !this.state.closed
                                                })
                                            }}
                                        >
                                            <FeatherIcon className="stroke-current" size={16} icon="x" />
                                        </button>
                                    </div>
                                    <div className={"flex flex-row-reverse bg-white h-full shadow-xl"}>
                                        <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                            <header className="p-6 bg-gray-100">
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Add keyword
                                                </h2>
                                            </header>
                                            <AddKeyword
                                                ref="slideout"
                                                negative={false}
                                                client={this.state.client}
                                                campaigns={this.state.campaigns}
                                                onChange={(keywords) => {
                                                    this.setState({
                                                        closed: true
                                                    }, () => {
                                                        keywords.map((item) => {
                                                            let keyword_data = {
                                                                adgroup: item.adgroup,
                                                                campaign: item.campaign,
                                                                channel: "google",
                                                                active_view_measurable_impressions: 0,
                                                                click: 0,
                                                                conversions: 0,
                                                                cpc: 0,
                                                                criterion_id: item.criterion_id,
                                                                ctr: 0,
                                                                id: 0,
                                                                impressions: 0,
                                                                spend: 0,
                                                                status: "active",
                                                                text: item.text,
                                                                top_impression_percentage: 0,
                                                                type: item.type,
                                                            }
                                                            this.props.setKeyword(keyword_data);
                                                        })
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="py-4 px-6 flex flex-row w-full">
                        <div className="w-64 mr-5">
                            <div className="text-xs font-bold mb-1">
                                Search
                            </div>
                            <input
                                autocomplete="off"
                                onChange={(event) => {
                                    this.setState({
                                        search: event.target.value
                                    })
                                }}
                                value={this.state.search}
                                className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                            />
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="border-b w-full" />
                }
                {
                    this.state.loading &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">Loading Adgroups ...</div>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="overflow-scroll">
                        <table className="min-w-full divide-y divide-gray-200 bg-white">
                            <thead>
                                <tr>
                                    <th className="transition-all duration-200 pl-6 pr-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions
                                        </div>
                                    </th>
                                    {
                                        [
                                            { title: "Campaign", value: "campaign" },
                                            { title: "Name", value: "name" },
                                            { title: "Status", value: "status" },
                                            { title: "Conversions", value: "conversions" },
                                            { title: "Impressions", value: "impressions" },
                                            { title: "Click", value: "click" },
                                            { title: "CTR", value: "ctr" },
                                            { title: "CPC", value: "cpc" },
                                            { title: "Spend", value: "spend" }
                                        ].map((item) => {
                                            return (
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: item.value,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200 text-left text-xs leading-4 px-6 py-3 font-medium uppercase " + (this.state.sort === item.value ? 'text-gray-700 border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        {item.title} <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {this.renders.filter().filter((item, i) => {
                                    return i < this.state.pagesize
                                }).sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            if (a[this.state.sort].value) {
                                                nameA = a[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameA = a[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            if (b[this.state.sort].value) {
                                                nameB = b[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameB = b[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row justify-start relative">
                                                        {
                                                            item.status.value !== "REMOVED" &&
                                                            <button onClick={() => {
                                                                this.setState({
                                                                    remove_modal: true,
                                                                    remove_adgroup: item
                                                                })
                                                            }} className="relative overflow-hidden mr-2 bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Remove</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="trash" />
                                                                {
                                                                    item.loading_remove &&
                                                                    <div className="absolute left-0 bg-red-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                        {
                                                            item.status.value === "ENABLED" &&
                                                            <button onClick={() => {
                                                                this.functions.status(item, "paused");
                                                            }} className="relative mr-2 overflow-hidden bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Pause</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="pause" />
                                                                {
                                                                    item.loading_status &&
                                                                    <div className="absolute left-0 bg-orange-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                        {
                                                            item.status.value === "PAUSED" &&
                                                            <button onClick={() => {
                                                                this.functions.status(item, "enabled");
                                                            }} className="relative mr-2 overflow-hidden bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Activate</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="play" />
                                                                {
                                                                    item.loading_status &&
                                                                    <div className="absolute left-0 bg-green-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                        {
                                                            <button onClick={() => {
                                                                this.setState({
                                                                    closed: false,
                                                                }, () => {
                                                                    if (!this.state.closed) {
                                                                        this.refs.slideout.functions.init(item);
                                                                    }
                                                                })
                                                            }} className="relative overflow-hidden bg-gray-200 bg-opacity-25 text-gray-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Add keyword</span>
                                                                {
                                                                    item.loading_keyword &&
                                                                    <div className="absolute left-0 bg-gray-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                                {
                                                    [
                                                        { title: "Campaign", value: "campaign" },
                                                        { title: "Name", value: "name" },
                                                        { title: "Status", value: "status" },
                                                        { title: "Conversions", value: "conversions" },
                                                        { title: "Impressions", value: "impressions" },
                                                        { title: "Click", value: "click" },
                                                        { title: "CTR", value: "ctr" },
                                                        { title: "CPC", value: "cpc" },
                                                        { title: "Spend", value: "spend" }
                                                    ].map((inner_item) => {
                                                        if (inner_item.value !== "status") {
                                                            return (
                                                                <td className={(this.state.sort === inner_item.value ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div
                                                                            className={"text-sm leading-5 text-gray-900"}>
                                                                            {item[inner_item.value]}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )
                                                        } else {
                                                            return (
                                                                <td className={(this.state.sort === inner_item.value ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}>
                                                                    <div
                                                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status.name === "Active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status.name === "Ended",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status.name === "Paused",
                                                                                ["bg-red-100 text-red-600"]: (item.status.name === "Error" || item.status.name === "Removed"),
                                                                            })
                                                                        }>
                                                                        {item.status.name}
                                                                    </div>
                                                                </td>
                                                            )
                                                        }

                                                    })
                                                }
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    !this.state.loading && (this.renders.filter().length > this.state.pagesize) &&
                    <div className="mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                        <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                            <div onClick={(e) => {
                                this.setState({
                                    pagesize: this.state.pagesize + 10
                                })
                            }} className="btn btn-primary btn-lg max-w-md flex-1">
                                <div>
                                    Show more ({this.renders.filter().length}/{this.state.pagesize})
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({
                                pagesize: 10000000000
                            })
                        }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                            show all
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.adgroups.length < 1 &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">No Adgroups available</div>
                    </div>
                }
            </div>
        )
    }

}

class Keywords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            keywords: [],
            adgroups: [],
            campaign: {},
            client: {},
            search: "",
            sort: 'conversions',
            order: 'desc',
            closed: true,
            pagesize: 10,
        };
    };

    componentDidMount() {
        this.setState({
            keywords: this.props.keywords ? this.props.keywords : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            client: this.props.client ? this.props.client : {},
        }, () => {
            if (this.state.client.id && this.state.campaigns.length > 0 && this.state.keywords.length < 1) {
                this.functions.get();
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            keywords: nextProps.keywords ? nextProps.keywords : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            client: nextProps.client ? nextProps.client : {}
        })
    }

    functions = {
        get: () => {
            if (Array.isArray(this.state.keywords) && this.state.keywords.length < 1) {
                this.setState({
                    loading: true
                }, () => {
                    calls.getPositiveKeywords(
                        this.state.client.id,
                        this.state.campaigns.map((item) => { return item.id }).join(',')
                    ).then((response) => {
                        let keywords = [];
                        if (response.data && response.data.positive) {
                            keywords = response.data.positive;
                        }
                        this.setState({
                            keywords: keywords,
                            loading: false
                        }, () => {
                            this.props.setKeywords(this.state.keywords);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        remove: (item) => {
            if (!item.loading) {
                item.loading = true;
                this.setState({
                    keywords: this.state.keywords
                }, () => {
                    calls.removeKeyword({
                        adgroup_id: item.adgroup,
                        criterion_id: item.criterion_id,
                        client: this.props.client.id,
                        positive: true
                    }).then((response) => {
                        this.setState({
                            keywords: this.state.keywords.filter((inner_item) => {
                                return inner_item.criterion_id !== item.criterion_id;
                            })
                        }, () => {
                            this.props.setKeywords(this.state.keywords);
                        })
                    }, (error) => {
                        item.loading = false;
                        this.setState({
                            keywords: this.state.keywords
                        });
                    });
                })
            }
        },
    };

    renders = {
        filter: () => {
            let options = this.state.keywords;
            let keywords = [];
            if (this.state.search && this.state.search !== "") {
                let clients = fuzzysort.go(this.state.search, options.map((item) => { return item.text }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].text.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        },
    };

    render() {
        return (
            <div>
                {
                    <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                        style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                        <div className="absolute inset-0 overflow-hidden">
                            <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                                <div
                                    className={`relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}>
                                    <div className="absolute right-100 pr-6 pt-6 flex">
                                        <button aria-label="Close panel"
                                            className="bg-red-500 text-white border-2 border-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                            onClick={() => {
                                                this.setState({
                                                    closed: !this.state.closed
                                                })
                                            }}
                                        >
                                            <FeatherIcon className="stroke-current" size={16} icon="x" />
                                        </button>
                                    </div>
                                    <div className={"flex flex-row-reverse bg-white h-full shadow-xl"}>
                                        <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                            <header className="p-6 bg-gray-100">
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Add keyword
                                                </h2>
                                            </header>
                                            <AddKeyword
                                                ref="slideout"
                                                negative={false}
                                                client={this.state.client}
                                                adgroups={this.state.adgroups}
                                                campaigns={this.state.campaigns}
                                                onChange={(client, campaigns) => {

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="py-4 px-6 flex flex-row w-full">
                        <div className="w-64 mr-5">
                            <div className="text-xs font-bold mb-1">
                                Search
                            </div>
                            <input
                                autocomplete="off"
                                onChange={(event) => {
                                    this.setState({
                                        search: event.target.value
                                    })
                                }}
                                value={this.state.search}
                                className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                            />
                        </div>
                        {
                            false &&
                            <div className="flex flex-1 justify-end">
                                <div>
                                    <div className="btn inline-block btn-primary" onClick={() => {
                                        this.setState({
                                            closed: false,
                                        }, () => {
                                            if (!this.state.closed) {
                                                this.refs.slideout.functions.init();
                                            }
                                        })
                                    }}>
                                        Add keyword
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="border-b w-full" />
                }
                {
                    this.state.loading &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">Loading Keywords ...</div>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="overflow-scroll">
                        <table className="min-w-full divide-y divide-gray-200 bg-white">
                            <thead>
                                <tr>
                                    <th className="transition-all duration-200 pl-6 pr-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions
                                        </div>
                                    </th>
                                    {
                                        [
                                            { title: "Campaign", value: "campaign" },
                                            { title: "Adgroup", value: "adgroup" },
                                            { title: "Text", value: "text" },
                                            { title: "Type", value: "type" },
                                            { title: "Status", value: "status" },
                                            { title: "Conversions", value: "conversions" },
                                            { title: "Impressions", value: "impressions" },
                                            { title: "Click", value: "click" },
                                            { title: "CTR", value: "ctr" },
                                            { title: "CPC", value: "cpc" },
                                            { title: "Spend", value: "spend" }
                                        ].map((item) => {
                                            return (
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: item.value,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200 text-left text-xs leading-4 px-6 py-3 font-medium uppercase " + (this.state.sort === item.value ? 'text-gray-700 border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        {item.title} <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {this.renders.filter().filter((item, i) => {
                                    return i < this.state.pagesize
                                }).sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            if (a[this.state.sort].value) {
                                                nameA = a[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameA = a[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            if (b[this.state.sort].value) {
                                                nameB = b[this.state.sort].value.toLowerCase();
                                            } else {
                                                nameB = b[this.state.sort].toLowerCase();
                                            }
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row justify-start relative">
                                                        {
                                                            <button onClick={() => {
                                                                this.functions.remove(item);
                                                            }} className="relative overflow-hidden mr-2 bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Remove</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="trash" />
                                                                {
                                                                    item.loading &&
                                                                    <div className="absolute left-0 bg-red-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                        {
                                                            false &&
                                                            item.status.value === "ENABLED" &&
                                                            <button onClick={() => {
                                                                this.functions.status(item, "paused");
                                                            }} className="relative mr-2 overflow-hidden bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Pause</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="pause" />
                                                                {
                                                                    item.loading_status &&
                                                                    <div className="absolute left-0 bg-orange-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                        {
                                                            false &&
                                                            item.status.value === "PAUSED" &&
                                                            <button onClick={() => {
                                                                this.functions.status(item, "enabled");
                                                            }} className="relative mr-2 overflow-hidden bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Activate</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="play" />
                                                                {
                                                                    item.loading_status &&
                                                                    <div className="absolute left-0 bg-green-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                                {
                                                    [
                                                        { title: "Campaign", value: "campaign" },
                                                        { title: "Adgroup", value: "adgroup" },
                                                        { title: "Text", value: "text" },
                                                        { title: "Type", value: "type" },
                                                        { title: "Status", value: "status" },
                                                        { title: "Conversions", value: "conversions" },
                                                        { title: "Impressions", value: "impressions" },
                                                        { title: "Click", value: "click" },
                                                        { title: "CTR", value: "ctr" },
                                                        { title: "CPC", value: "cpc" },
                                                        { title: "Spend", value: "spend" }
                                                    ].map((inner_item) => {
                                                        if (inner_item.value !== "status") {
                                                            return (
                                                                <td className={(this.state.sort === inner_item.value ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div
                                                                            className={"text-sm leading-5 text-gray-900"}>
                                                                            {item[inner_item.value]}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )
                                                        } else {
                                                            return (
                                                                <td className={(this.state.sort === inner_item.value ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}>
                                                                    <div
                                                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "ended",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                                ["bg-gray-100 text-gray-500 bg-gray-50"]: item.status === "unknown",
                                                                                ["bg-red-100 text-red-600"]: (item.status === "error" || item.status === "removed"),
                                                                            })
                                                                        }>
                                                                        {item.status}
                                                                    </div>
                                                                </td>
                                                            )
                                                        }

                                                    })
                                                }
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    !this.state.loading && (this.renders.filter().length > this.state.pagesize) &&
                    <div className="mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                        <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                            <div onClick={(e) => {
                                this.setState({
                                    pagesize: this.state.pagesize + 10
                                })
                            }} className="btn btn-primary btn-lg max-w-md flex-1">
                                <div>
                                    Show more ({this.renders.filter().length}/{this.state.pagesize})
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({
                                pagesize: 10000000000
                            })
                        }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                            show all
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.keywords.length < 1 &&
                    <div className="h-48  justify-center align-middle flex flex-col text-center">
                        <div className="font-bold mb-2">No Keywords available</div>
                    </div>
                }
            </div>
        )
    }

}

export default UserOptimizeAll;