import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import moment from 'moment';
import fuzzysort from 'fuzzysort';
import SlideDown from 'react-slidedown';
import { BeatLoader } from 'react-spinners';
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import { CirclePicker } from 'react-color';
import SingleDatepicker from "../modules/singleDatepicker";
import CreateCustomMetric from "../modules/createCustomMetric";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import GoogleAnalyticsDimensions from '../assets/json/dimensions/google_analytics.json';
import GoogleAnalytics4Dimensions from '../assets/json/dimensions/google_analytics_4.json';
import AdformDimensions from '../assets/json/dimensions/adform.json';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import InputTailwind from './inputTailwind';

class createOrderABTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datasource: true,
            goal: {},
            data: {},
            order: {},
            client: {},
            campaigns: [],
            adgroups: [],
            ads: [],
            daterange: {},
            selected_campaigns: {},
            selected_adgroups: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_metrics: {},
            metrics: {},
            closed: true,
            channels: [],
            settings: false,
            datasource: false,
            dateranges: false,
            naming: false,
            error: false,
            error_message: "",
        };
    };

    componentDidMount() {
        this.setState({
            closed: this.props.closed,
            all_metrics: this.props.metrics,
            campaigns: this.props.campaigns,
            metrics: this.props.metrics,
            adgroups: this.props.adgroups,
            order: this.props.order,
            client: this.props.client,
        });
        if (this.props.closed === true) {
            this.setState({
                name: this.state.closed,
                selected_campaigns_internal: this.state.selected_campaigns_internal,
                selected_adgroups_internal: this.state.selected_adgroups_internal,
                selected_metrics: this.state.selected_metrics,
                goal: this.state.goal,
                daterange: this.state.daterange,
                error: false,
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            metrics: nextProps.metrics,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            order: nextProps.order,
            client: nextProps.client,
        });
        if (nextProps.closed === true) {
            this.setState({
                name: "",
                selected_campaigns_internal: {},
                selected_adgroups_internal: {},
                selected_metrics: {},
                goal: {},
                daterange: {},
                error: false,
            });
        }
    }

    functions = {
        valid: () => {
            let valid = false;
            //NAME
            if (this.state.name !== "" && this.state.daterange.start_date && this.state.daterange.end_date && this.functions.anythingSelected() && Object.keys(this.state.selected_metrics).length > 0) {
                valid = true;
            }
            return valid;

        },
        create: async () => {
            if (this.functions.valid()) {
                let campaigns = [];
                if (Object.keys(this.state.selected_campaigns_internal).length > 0) {
                    Object.keys(this.state.selected_campaigns_internal).map((key) => {
                        campaigns.push({ id: this.state.selected_campaigns_internal[key].id, channel: this.state.selected_campaigns_internal[key].channel, hex: this.state.selected_campaigns_internal[key].hex });
                    });
                }
                let adgroups = [];
                if (Object.keys(this.state.selected_adgroups_internal).length > 0) {
                    Object.keys(this.state.selected_adgroups_internal).map((key) => {
                        adgroups.push({ id: this.state.selected_adgroups_internal[key].id, channel: this.state.selected_adgroups_internal[key].channel, hex: this.state.selected_adgroups_internal[key].hex, budget: this.state.selected_adgroups_internal[key].budget ? this.state.selected_adgroups_internal[key].budget : 0 });
                    });
                }






                let body = {
                    id: moment().unix().toString() + Math.floor((Math.random() * 9999999) + 1),
                    name: this.state.name,
                    order_id: this.state.order.id,
                    startDate: this.state.daterange.start_date,
                    endDate: this.state.daterange.end_date,
                    metric: this.state.selected_metrics[Object.keys(this.state.selected_metrics)[0]].id,
                    campaigns: campaigns,
                    adgroups: adgroups,
                    goal: ""
                };

                try {
                    //CALL FACEBOOK API IF MORE THEN 2 FB IS SELECTED
                    if ((campaigns.length > 0 && campaigns.filter((campaign) => { return campaign.channel === "facebook" }).length >= 2) || (adgroups.length > 0 && adgroups.filter((adgroup) => { return adgroup.channel === "facebook" }).length >= 2)) {
                        let resp = await this.calls.FBcreateSplitTest(body);
                    }
                    if ((adgroups.length > 0 && adgroups.filter((adgroup) => { return adgroup.channel === "linkedin" }).length >= 2)) {
                        let resp = await this.calls.LinkedincreateSplitTest(body);
                    }
                    if ((adgroups.length > 0 && adgroups.filter((adgroup) => { return adgroup.channel === "tiktok" }).length >= 2)) {
                        let resp = await this.calls.TikTokcreateSplitTest(body);
                    }
                    let response = await this.calls.update(body);
                    this.props.create();

                    this.props.toggle();

                } catch (error) {
                    this.setState({ error: true, error_message: error.body && error.body.message ? error.body.message : "Something went wrong" });
                    console.log(error, "ERRROR");
                }


            } else {

                //NOT VALID

            }
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        },
    };

    calls = {
        FBcreateSplitTest: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/createSplitTest?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        TikTokcreateSplitTest: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/createSplitTest?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        LinkedincreateSplitTest: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/createSplitTest?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAbTest?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },

    };

    render() {
        return (

            <Transition.Root show={!this.state.closed} as={Fragment}>
                <Dialog as="div" style={{ zIndex: 9999, position: "absolute" }} className="absolute inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 transition duration-500 ease-in-out bg-black bg-opacity-25" />
                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-6 px-4 bg-purple-500 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">Create A/B test</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-purple-500 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-indigo-300">
                                                        Get started by filling in the information below to create your A/B test.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">

                                                {/*SELECT NAME*/}
                                                <SetName
                                                    name={this.state.name}
                                                    open={this.state.naming}
                                                    onToggle={(value) => {
                                                        this.setState({
                                                            naming: !value
                                                        })
                                                    }}
                                                    onChange={(name) => {
                                                        this.setState({
                                                            name: name
                                                        })
                                                    }}
                                                    onNext={() => {
                                                        this.setState({
                                                            naming: false,
                                                            dateranges: true
                                                        })
                                                    }}
                                                />
                                                {/*SELECT DATE RANGE*/}
                                                {
                                                    this.state.name && this.state.name !== "" &&
                                                    <SelectDaterange
                                                        open={this.state.dateranges}
                                                        daterange={this.state.daterange}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                dateranges: !value
                                                            })
                                                        }}
                                                        onDates={(dates) => {
                                                            this.setState({
                                                                daterange: dates
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                dateranges: false,
                                                                datasource: true,
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL DATASOURCE*/}
                                                {
                                                    this.state.daterange && this.state.daterange.start_date && this.state.daterange.end_date &&
                                                    this.state.name && this.state.name !== "" &&
                                                    <SelectDatasource
                                                        campaigns={this.state.campaigns}
                                                        adgroups={this.state.adgroups}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        open={this.state.datasource}
                                                        getAdgroups={(campaign) => {
                                                            if (this.props.getAdgroups) {
                                                                this.props.getAdgroups(campaign);
                                                            }
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                datasource: !value
                                                            })
                                                        }}
                                                        onCampaigns={(campaigns) => {
                                                            this.setState({
                                                                campaigns: campaigns
                                                            })
                                                        }}
                                                        onSelectedCampaigns={(campaigns) => {
                                                            let buffer = campaigns ? Object.keys(campaigns) : [];
                                                            let channels = [];
                                                            if (buffer.length > 0) {
                                                                for (let i = 0; i < buffer.length; i++) {
                                                                    if (!channels.includes(campaigns[buffer[i]].channel)) {
                                                                        channels.push(campaigns[buffer[i]].channel);
                                                                    }
                                                                }
                                                            }
                                                            if (this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0) {
                                                                let buff = false;
                                                                channels.map((channel) => {
                                                                    if (!Object.keys(this.state.selected_metrics[Object.keys(this.state.selected_metrics)[0]].channels).includes(channel)) {
                                                                        buff = true;
                                                                    }
                                                                })
                                                                if (buff) {
                                                                    this.setState({
                                                                        selected_metrics: {}
                                                                    })
                                                                }
                                                            }
                                                            this.setState({
                                                                selected_campaigns_internal: campaigns,
                                                                channels: channels
                                                            })

                                                        }}
                                                        onAdgroups={(adgroups) => {
                                                            this.setState({
                                                                adgroups: adgroups
                                                            })
                                                        }}
                                                        onSelectedAdgroups={(adgroups) => {
                                                            let buffer = adgroups ? Object.keys(adgroups) : [];
                                                            let channels = [];
                                                            if (buffer.length > 0) {
                                                                for (let i = 0; i < buffer.length; i++) {
                                                                    if (!channels.includes(adgroups[buffer[i]].channel)) {
                                                                        channels.push(adgroups[buffer[i]].channel);
                                                                    }
                                                                }
                                                            }
                                                            if (this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0) {
                                                                let buff = false;
                                                                channels.map((channel) => {
                                                                    if (!Object.keys(this.state.selected_metrics[Object.keys(this.state.selected_metrics)[0]].channels).includes(channel)) {
                                                                        buff = true;
                                                                    }
                                                                })
                                                                if (buff) {
                                                                    this.setState({
                                                                        selected_metrics: {}
                                                                    })
                                                                }
                                                            }
                                                            this.setState({
                                                                selected_adgroups_internal: adgroups,
                                                                channels: channels
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                datasource: false,
                                                                metric: true
                                                            })
                                                        }}
                                                    />
                                                }
                                                {/*SELECT METRIC*/}
                                                {

                                                    this.state.name && this.state.name !== "" &&
                                                    this.state.daterange && this.state.daterange.start_date && this.state.daterange.end_date &&
                                                    ((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 1) || (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 1)) &&

                                                    <SelectMetric
                                                        selected_metrics={this.state.selected_metrics}
                                                        open={this.state.metric}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        channels={this.state.channels}
                                                        metrics={this.state.metrics}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                metric: !value
                                                            })
                                                        }}
                                                        onSelect={(metric) => {
                                                            this.setState({
                                                                selected_metrics: metric
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                settings: true,
                                                                metric: false
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SET COLOR*/}
                                                {
                                                    this.state.name && this.state.name !== "" &&
                                                    this.state.daterange && this.state.daterange.start_date && this.state.daterange.end_date &&
                                                    ((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 1) || (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 1)) &&
                                                    this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0 &&

                                                    <SetSettings
                                                        open={this.state.settings}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedMetrics={this.state.selected_metrics}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                settings: !value
                                                            })
                                                        }}
                                                        onSelected={(object) => {
                                                            this.setState({
                                                                selected_campaigns_internal: object.selected_campaigns_internal,
                                                                selected_adgroups_internal: object.selected_adgroups_internal
                                                            });
                                                        }}
                                                    />
                                                }



                                            </div>
                                        </div>
                                        {
                                            Object.keys(this.state.selected_adgroups_internal).length > 0 && Object.keys(this.state.selected_adgroups_internal).map((item) => {
                                                if (this.state.selected_adgroups_internal[item].channel === "linkedin" || this.state.selected_adgroups_internal[item].channel === "tiktok") {
                                                    return true;
                                                } else return false;
                                            }).filter((item) => { return item }).length > 0 &&
                                            <div className="bg-orange-600 border text-white px-4 py-3 relative text-xs" role="alert">Warning! For LinkeIn and TikTok you can only select two adgroup</div>
                                        }
                                        {
                                            Object.keys(this.state.selected_campaigns_internal).length > 0 && (Object.keys(this.state.selected_campaigns_internal).map((item) => {
                                                if (this.state.selected_campaigns_internal[item].channel === "linkedin") {
                                                    return true;
                                                } else return false;
                                            }).filter((item) => { return item }).length > 1 || Object.keys(this.state.selected_campaigns_internal).map((item) => {
                                                if (this.state.selected_campaigns_internal[item].channel === "tiktok") {
                                                    return true;
                                                } else return false;
                                            }).filter((item) => { return item }).length > 1) &&
                                            <div className="bg-orange-600 border text-white px-4 py-3 relative text-xs" role="alert">Warning! You have selected 2 or more campaigns from LinkeIn or TikTok, we recommend using adgroups instead to ensure the test is not overlapping same audiences</div>
                                        }
                                        {
                                            this.state.error &&
                                            <div className="bg-red-700 border text-white px-4 py-3 relative" role="alert">
                                                {this.state.error_message}
                                            </div>
                                        }
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                type="button"
                                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    this.props.toggle()
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => {
                                                    this.functions.create();
                                                }}
                                                className={(this.functions.valid() ? "" : "opacity-50 cursor-not-allowed") + " ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

class SetName extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: ""
        };
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            name: this.props.name
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            name: nextProps.name
        })
    }

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-bold">
                                Set A/B Test name
                            </h3>
                            {
                                this.state.name && this.state.name !== "" &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected: <span className="font-bold ml-1">{this.state.name}</span>
                                </p>
                            }
                        </div>
                        <div className="flex flex-row">
                            {
                                this.state.name && this.state.name !== "" &&
                                <div
                                    className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"check"} />
                                </div>
                            }
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            <InputTailwind
                                label={"Name"}
                                value={this.state.name}
                                onChange={(value) => {
                                    if (this.props.onChange) {
                                        this.props.onChange(value);
                                    }
                                }}
                            />
                        </div>
                        {
                            this.state.name && this.state.name !== "" &&
                            <div className="flex justify-center items-center">
                                <div onClick={() => {
                                    if (this.props.onNext) {
                                        this.props.onNext();
                                    }
                                }}
                                    className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                </div>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}


class SetSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_metrics: {},
        };
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_metrics: this.props.selectedMetrics ? this.props.selectedMetrics : {},
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_metrics: nextProps.selectedMetrics ? nextProps.selectedMetrics : {},
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        selectedDataSources: () => {
            let sources = [];
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    sources.push(this.state.selected_campaigns_internal[key]);
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    sources.push(this.state.selected_adgroups_internal[key]);
                }
            }

            return sources;
        },
    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-bold">
                                Settings
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                Select color of each data source
                            </p>
                        </div>
                        <div className="flex flex-row">
                            <div
                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                <FeatherIcon className={'stroke-current'} size={14}
                                    icon={"check"} />
                            </div>
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="mb-4">
                            <div className="text-xs font-bold mb-1">
                                Chart colors
                            </div>
                            {
                                this.functions.selectedDataSources().map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className="flex flex-1 flex-row justify-center mb-2">
                                                <div onClick={() => {
                                                    item.setColor = !item.setColor;
                                                    this.props.onSelected({
                                                        selected_campaigns_internal: this.state.selected_campaigns_internal,
                                                        selected_adgroups_internal: this.state.selected_adgroups_internal
                                                    });
                                                }}
                                                    style={{ backgroundColor: "#" + item.hex ? item.hex : "ffffff" }}
                                                    className="cursor-pointer mr-2 h-8 w-8 rounded-full text-purple-500 flex justify-center align-middle items-center">
                                                </div>
                                                <div
                                                    className="flex flex-1 flex-col truncate">
                                                    <div
                                                        className="font-bold text-xs truncate">
                                                        {item.name}
                                                    </div>
                                                    <div className="text-xxs">
                                                        Source color {item.hex}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                item.setColor &&
                                                <div className="mt-3">
                                                    <CirclePicker
                                                        width={"100%"}
                                                        colors={["#3A5998", "#4384F4", "#0D65A1", "#50ABF1", "#e8710a", "#34A853", "#60C1A4", "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
                                                        circleSize={31}
                                                        onChange={(value) => {
                                                            item.hex = value.hex;
                                                            item.rgb = value.rgb;
                                                            item.setColor = false;
                                                            this.props.onSelected({
                                                                selected_campaigns_internal: this.state.selected_campaigns_internal,
                                                                selected_adgroups_internal: this.state.selected_adgroups_internal
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                Object.keys(this.state.selected_metrics).map((metric) => {
                                                    return (
                                                        <div className="ml-10">
                                                            <div
                                                                className="flex flex-1 flex-row justify-center mb-2">
                                                                {
                                                                    <div
                                                                        style={{ backgroundColor: "#" + item.hex ? item.hex : "ffffff" }}
                                                                        className="cursor-pointer mr-2 h-8 w-8 rounded-full text-purple-500 flex justify-center align-middle items-center">
                                                                    </div>
                                                                }
                                                                <div
                                                                    className="flex flex-1 flex-col truncate">
                                                                    <div
                                                                        className="font-bold text-xs truncate">
                                                                        {this.state.selected_metrics[metric].name}
                                                                    </div>
                                                                    <div className="text-xxs">
                                                                        Metric
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </SlideDown>
                </div>
            </>
        )
    }
}

class SelectDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            show: {}
        };
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
        })
    }

    functions = {
        valid: (item) => {
            if (Object.keys(this.state.selected_adgroups_internal).length > 0) {
                let linkedin = [];
                let tiktok = [];
                Object.keys(this.state.selected_adgroups_internal).map((key) => {
                    if (this.state.selected_adgroups_internal[key].channel === "linkedin") {
                        linkedin.push(this.state.selected_adgroups_internal[key]);
                    } else if (this.state.selected_adgroups_internal[key].channel === "tiktok") {
                        tiktok.push(this.state.selected_adgroups_internal[key]);
                    }
                });
                if (item.channel === "linkedin") {
                    if (linkedin.length > 1) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (item.channel === "tiktok") {
                    if (tiktok.length > 1) {
                        return false;
                    } else {
                        if (tiktok.length === 1 && (tiktok[0].budget !== item.budget || tiktok[0].budget_mode.id !== item.budget_mode.id || tiktok[0].campaign_id !== item.campaign_id)) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                } else return true;
            } else {
                if (item.channel === "tiktok") {
                    if (!item.end) {
                        return false;
                    } else return true;
                } else return true;
            }
        },
        tiktokValid: (item) => {

            if (!item.end) {
                return false;
            } else {
                if (Object.keys(this.state.selected_adgroups_internal).length > 0) {
                    let tiktok = [];
                    Object.keys(this.state.selected_adgroups_internal).map((key) => {
                        if (this.state.selected_adgroups_internal[key].channel === "tiktok") {
                            tiktok.push(this.state.selected_adgroups_internal[key]);
                        }
                    });
                    if (tiktok.length < 3) {
                        if (tiktok[0].campaign_id !== item.campaign_id) {
                            return true; 
                        } else if (tiktok[0].budget !== item.budget || tiktok[0].budget_mode.id !== item.budget_mode.id) {
                            return false;
                        } else return true;
                    } else return true;
                } else return true;
            }
        },
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            colors.hex = "##3A5998";
            if (item.channel === "facebook") {
                colors.hex = "#3A5998";
            } else if (item.channel === "google") {
                colors.hex = "#4384F4";
            } else if (item.channel === "linkedin") {
                colors.hex = "#0D65A1";
            } else if (item.channel === "google_analytics") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_analytics_4") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_shopping") {
                colors.hex = "#34A853";
            } else if (item.channel === "adform") {
                colors.hex = "#60C1A4";
            } else if (item.channel === "bidtheatre") {
                colors.hex = "#f48e22";
            } else if (item.channel === "tiktok") {
                colors.hex = "#000000";
            } else if (item.channel === "twitter") {
                colors.hex = "#50ABF1";
            } else if (item.channel === "snapchat") {
                colors.hex = "#FFFC00";
            } else if (item.channel === "bing") {
                colors.hex = "#0C8484";
            }
            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 1) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 1) {
                total = total + 1;
            }
            return total > 0
        }
    };

    render() {
        return (
            <>
                <div
                    className={(" ") + " pt-6 px-4 sm:px-6 pb-6 border-b"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-bold">
                                Select data source
                            </h3>
                            {
                                !this.functions.anythingSelected() &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Display data from selected data sources
                                </p>
                            }
                            {
                                this.functions.anythingSelected() &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Your have selected
                                    {
                                        this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_campaigns_internal).length} campaigns</span>
                                    }
                                    {
                                        this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_adgroups_internal).length} adgroups</span>
                                    }
                                </p>
                            }
                        </div>
                        <div className="flex flex-row">
                            {
                                this.functions.anythingSelected()
                                &&
                                <div
                                    className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"check"} />
                                </div>
                            }
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={14}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        {
                            this.state.campaigns.map((campaign, index) => {
                                return (
                                    <Fragment>
                                        <div
                                            className="flex flex-1 flex-row justify-center mb-2">
                                            <div onClick={() => {
                                                //GA ONLY HAVE CAMPAIGN LEVEL
                                                if (campaign.channel !== "google_analytics" && campaign.channel !== "google_analytics_4") {
                                                    if (!campaign.open) {
                                                        if (this.props.getAdgroups) {
                                                            this.props.getAdgroups(campaign);
                                                            campaign.open = true;
                                                        }
                                                    } else {
                                                        campaign.open = false;
                                                    }
                                                    this.setState({
                                                        campaigns: this.state.campaigns
                                                    }, () => {
                                                        if (this.props.onCampaigns) {
                                                            this.props.onCampaigns(this.state.campaigns);
                                                        }
                                                    })
                                                }
                                            }}
                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                {
                                                    campaign.channel !== "google_analytics" &&
                                                    campaign.channel !== "google_analytics_4" &&
                                                    !campaign.open && !campaign.loading &&
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14}
                                                        icon="plus" />
                                                }
                                                {
                                                    campaign.channel !== "google_analytics" &&
                                                    campaign.channel !== "google_analytics_4" &&
                                                    campaign.open && !campaign.loading &&
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14}
                                                        icon="minus" />
                                                }
                                                {
                                                    campaign.channel !== "google_analytics" &&
                                                    campaign.channel !== "google_analytics_4" &&
                                                    campaign.loading &&
                                                    <BeatLoader
                                                        sizeUnit={"px"}
                                                        size={4}
                                                        color={'#060534'}
                                                        loading={true}
                                                    />
                                                }
                                            </div>
                                            <div onClick={() => {

                                                if (!campaign.is_loading) {
                                                    if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length < 1) {
                                                        if (this.state.selected_campaigns_internal[campaign.id]) {
                                                            delete this.state.selected_campaigns_internal[campaign.id]
                                                        } else {
                                                            if (Object.keys(this.state.selected_campaigns_internal).length < 10) {
                                                                let colors = this.functions.color(campaign);
                                                                campaign.hex = colors.hex;
                                                                campaign.rgb = colors.rgb;
                                                                this.state.selected_campaigns_internal[campaign.id] = campaign;
                                                            }
                                                        }
                                                        this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                                    }
                                                }

                                            }}
                                                className={(this.state.selected_campaigns_internal[campaign.id] ? "border-purple-500 bg-purple-100" : ((this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0 || Object.keys(this.state.selected_campaigns_internal).length > 9) ? "border-gray-500 bg-gray-100" : "")) + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                {
                                                    this.state.selected_campaigns_internal[campaign.id] &&
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14}
                                                        icon="check" />
                                                }
                                                {
                                                    campaign.is_loading &&
                                                    <div
                                                        className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={4}
                                                            color={'#060534'}
                                                            loading={true}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                campaign.channel === "google" &&
                                                <div
                                                    className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/google_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "google_shopping" &&
                                                <div
                                                    className="bg-googleshopping-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/google-shopping.png')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "linkedin" &&
                                                <div
                                                    className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "facebook" &&
                                                <div
                                                    className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img style={{ width: '0.4rem' }}
                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "google_analytics" &&
                                                <div
                                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/google_analytics_icon.png')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "google_analytics_4" &&
                                                <div
                                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/ga_icon.png')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "adform" &&
                                                <div
                                                    className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/adform_icon.png')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "twitter" &&
                                                <div
                                                    className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "tiktok" &&
                                                <div
                                                    className="bg-tiktok-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "bidtheatre" &&
                                                <div
                                                    className="bg-bidtheatre-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/bidtheatre_icon.png')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "snapchat" &&
                                                <div
                                                    className="bg-snapchat-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/snapchat.svg')} />
                                                </div>
                                            }
                                            {
                                                campaign.channel === "bing" &&
                                                <div
                                                    className="bg-bing-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/bing-logo.png')} />
                                                </div>
                                            }
                                            <div className="flex flex-1 flex-col truncate">
                                                <div className="font-bold text-xs truncate">
                                                    {campaign.name}
                                                </div>
                                                <div className="text-xxs flex flex-row">
                                                    #{campaign.id}
                                                </div>
                                            </div>
                                        </div>
                                        {

                                            this.state.adgroups.filter((adgroup) => {
                                                return adgroup.campaign == campaign.id && campaign.open
                                            }).length > 0 && false &&
                                            <div
                                                className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                <div
                                                    className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500"></div>
                                                <div onClick={() => {
                                                    if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length < 1) {


                                                        if (this.state.adgroups.filter((adgroup) => {
                                                            return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                        }).length !== this.state.adgroups.filter((adgroup) => {
                                                            return adgroup.campaign == campaign.id
                                                        }).length) {
                                                            if ((this.state.adgroups.filter((adgroup) => {
                                                                return adgroup.campaign == campaign.id && !this.state.selected_adgroups_internal[adgroup.id]
                                                            }).length + Object.keys(this.state.selected_adgroups_internal).length) <= 10) {
                                                                //SELECT ALL
                                                                this.state.adgroups.filter((adgroup) => {
                                                                    return adgroup.campaign == campaign.id
                                                                }).map((adgroup) => {
                                                                    let colors = this.functions.color(adgroup);
                                                                    adgroup.hex = colors.hex;
                                                                    adgroup.rgb = colors.rgb;
                                                                    this.state.selected_adgroups_internal[adgroup.id] = adgroup;
                                                                });
                                                            }



                                                        } else {
                                                            //REMOVE ALL
                                                            this.state.adgroups.filter((adgroup) => {
                                                                return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                            }).map((adgroup) => {
                                                                delete this.state.selected_adgroups_internal[adgroup.id];
                                                            });
                                                        }
                                                        this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                                    }
                                                }}
                                                    className={(this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                    }).length === this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id
                                                    }).length ? "border-purple-500 bg-purple-100" : ((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0 || ((this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id && !this.state.selected_adgroups_internal[adgroup.id]
                                                    }).length + Object.keys(this.state.selected_adgroups_internal).length) > 10)) ? "border-gray-500 bg-gray-100" : "")) + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.adgroups.filter((adgroup) => {
                                                            return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                        }).length === this.state.adgroups.filter((adgroup) => {
                                                            return adgroup.campaign == campaign.id
                                                        }).length &&
                                                        <FeatherIcon
                                                            className={'stroke-current'}
                                                            size={14}
                                                            icon="check" />
                                                    }
                                                    {
                                                        this.state.adgroups.filter((adgroup) => {
                                                            return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id] && adgroup.is_loading
                                                        }).length > 0 &&
                                                        <div
                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                            <BeatLoader
                                                                sizeUnit={"px"}
                                                                size={4}
                                                                color={'#060534'}
                                                                loading={true}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                    }).length === this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id
                                                    }).length &&
                                                    <div
                                                        className="flex flex-1 flex-col truncate">
                                                        <div
                                                            className="font-bold text-xs truncate">
                                                            Deselect all
                                                        </div>
                                                        <div className="text-xxs">
                                                            Click here to deselect all adgroups
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                    }).length !== this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign == campaign.id
                                                    }).length &&
                                                    <div
                                                        className="flex flex-1 flex-col truncate">
                                                        <div
                                                            className="font-bold text-xs truncate">
                                                            Select all
                                                        </div>
                                                        <div className="text-xxs">
                                                            Click here to select all adgroups
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.adgroups.filter((adgroup) => {
                                                return adgroup.campaign == campaign.id && campaign.open
                                            }).map((adgroup) => {
                                                return (
                                                    <Fragment>
                                                        <div
                                                            className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                            <div className="cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center text-purple-500">

                                                            </div>
                                                            <div onClick={(adgroup_) => {
                                                                if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length < 1) {
                                                                    if (!adgroup.is_loading) {

                                                                        if (this.state.selected_adgroups_internal[adgroup.id]) {
                                                                            delete this.state.selected_adgroups_internal[adgroup.id];
                                                                        } else {

                                                                            if (Object.keys(this.state.selected_adgroups_internal).length < 10) {
                                                                                if (this.functions.valid(adgroup)) {
                                                                                    let colors = this.functions.color(adgroup);
                                                                                    adgroup.hex = colors.hex;
                                                                                    adgroup.rgb = colors.rgb;
                                                                                    this.state.selected_adgroups_internal[adgroup.id] = adgroup;
                                                                                }
                                                                            }
                                                                        }
                                                                        this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);

                                                                    }
                                                                }
                                                            }}
                                                                className={(this.state.selected_adgroups_internal[adgroup.id] ? "border-purple-500 bg-purple-100" : ((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0 || Object.keys(this.state.selected_adgroups_internal).length > 9) ? "border-gray-500 bg-gray-100" : "")) + (!this.functions.valid(adgroup) ? " border-gray-500 bg-gray-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                {
                                                                    this.state.selected_adgroups_internal[adgroup.id] &&
                                                                    <FeatherIcon
                                                                        className={'stroke-current'}
                                                                        size={14}
                                                                        icon="check" />
                                                                }
                                                                {
                                                                    adgroup.is_loading &&
                                                                    <div
                                                                        className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={4}
                                                                            color={'#060534'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="flex flex-1 flex-col truncate relative">
                                                                <div
                                                                    className="font-bold text-xs truncate flex items-center">
                                                                    {
                                                                        adgroup.channel === "tiktok" && !this.functions.tiktokValid(adgroup) && !this.state.selected_adgroups_internal[adgroup.id] &&
                                                                        <div
                                                                            onMouseEnter={() => { this.state.show[adgroup.id] = true; this.setState({ show: this.state.show }) }}
                                                                            onMouseLeave={() => { this.state.show[adgroup.id] = false; this.setState({ show: this.state.show }) }}
                                                                        >
                                                                            <ExclamationIcon className="h-4 w-4 text-red-600 mx-1" />
                                                                        </div>
                                                                    }
                                                                    {adgroup.name}

                                                                </div>
                                                                <div className="text-xxs">
                                                                    #{adgroup.id}
                                                                </div>
                                                                {
                                                                    adgroup.channel === "tiktok" && !this.functions.tiktokValid(adgroup) && this.state.show[adgroup.id] && !this.state.selected_adgroups_internal[adgroup.id] &&
                                                                    <div className={"text-xxs text-red-600 ease-in-out" + (this.state.show[adgroup.id] ? " opacity-100" : " opacity-0")}>
                                                                        Adgroup have no End Date <br />
                                                                        or Budget is not equal to selected
                                                                    </div>
                                                                }


                                                            </div>
                                                        </div>

                                                    </Fragment>
                                                )
                                            })
                                        }
                                        {
                                            (this.state.campaigns.length - 1) !== index &&
                                            <div className="border-t flex-1 flex mb-2" />
                                        }
                                    </Fragment>
                                )
                            })
                        }
                        {
                            this.functions.anythingSelected()
                            &&
                            <div className="flex justify-center items-center">
                                <div onClick={() => {
                                    this.props.onNext();
                                }}
                                    className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-3">NEXT
                                </div>
                            </div>
                        }
                    </SlideDown>
                </div >
            </>
        )
    }

}


class SelectDaterange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            daterange: {}
        };
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            daterange: this.props.daterange
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            daterange: nextProps.daterange
        })
    }

    functions = {
        valid: () => {
            return true;
        }
    };

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-bold">
                                Select date range
                            </h3>
                            {
                                this.state.daterange.start_date && this.state.daterange.end_date &&
                                <div className="text-xxs flex items-center text-gray-700 leading-tight">
                                    From <span className="font-bold ml-1">{this.state.daterange.start_date}</span> to <span className="font-bold ml-1">{this.state.daterange.end_date}</span>
                                </div>
                            }
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    (
                                        this.state.daterange.start_date && this.state.daterange.end_date
                                    ) &&
                                    <div
                                        className="mr-2 border-green-500 border-2 text-green-500 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="w-full">
                            <SingleDatepicker
                                minDateToday={true}
                                from={this.state.daterange.start_date}
                                to={this.state.daterange.end_date}
                                onChange={(data) => {
                                    if (!this.state.daterange) {
                                        this.state.daterange = {};
                                    }
                                    this.state.daterange.start_date = data.start_date;
                                    this.state.daterange.end_date = data.end_date;
                                    this.props.onDates(this.state.daterange);
                                    if (this.state.daterange.start_date && this.state.daterange.end_date) {
                                        this.props.onNext();
                                    }
                                }}
                            />
                        </div>

                        {
                            this.state.daterange.start_date && this.state.daterange.end_date &&
                            <div className="flex justify-center items-center">
                                <div onClick={() => {
                                    this.props.onNext();
                                }}
                                    className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                </div>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

class SelectMetric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_metrics: {},
            metrics: [],
            default_metrics: [],
            channels: [],
            categories: [{ name: "custom" }, { name: "calculated" }]
        };
    };

    componentDidMount() {
        let metrics = { ...(this.props.metrics ? this.props.metrics : {}) }
        if (this.props.channels && this.props.channels.length > 0) {
            if (metrics.custom) {
                metrics.custom = metrics.custom.filter((metric) => { return this.props.channels.map((channel) => { return metric.channels[channel] }).filter((item) => { return item }).length === this.props.channels.length })
            }
        }
        this.setState({
            metrics: metrics,
            selected_metrics: this.props.selected_metrics ? this.props.selected_metrics : {},
            open: this.props.open,
            channels: this.props.channels ? this.props.channels : [],
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let metrics = { ...(nextProps.metrics ? nextProps.metrics : {}) }
        if (nextProps.channels && nextProps.channels.length > 0) {
            if (metrics.custom) {
                metrics.custom = metrics.custom.filter((metric) => { return nextProps.channels.map((channel) => { return metric.channels[channel] }).filter((item) => { return item }).length === nextProps.channels.length })
            }
        }
        this.setState({
            metrics: metrics,
            selected_metrics: nextProps.selected_metrics ? nextProps.selected_metrics : {},
            open: nextProps.open,
            channels: nextProps.channels ? nextProps.channels : [],
        })
    }

    functions = {
        valid: () => {
            return this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0;
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        },
        calculatedMetric: (metric) => {
            let new_string = JSON.parse(JSON.stringify(metric.calculation));
            for (let key in metric.metrics) {
                new_string = new_string.replace("<" + key + ">", metric.metrics[key].name);
            }
            return new_string;
        }
    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer truncate">
                            <h3 className="mb-0 font-bold">
                                Select metrics
                            </h3>
                            {
                                this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0 &&
                                <div className="text-xxs truncate pr-3">
                                    You have selected <span className="font-bold">{Object.keys(this.state.selected_metrics).join(", ")}</span>
                                </div>
                                ||
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Choose between default, custom and calculated
                                </p>
                            }
                        </div>
                        {
                            <div className="flex flex-row">
                                {
                                    this.functions.valid() &&
                                    <div
                                        className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.state.categories.map((category) => {
                                    return (
                                        <Fragment>
                                            <div className="">
                                                <div
                                                    className={"cursor-pointer flex flex-1 flex-row justify-center mb-2"}>
                                                    <div onClick={() => {
                                                        category.open = !category.open;
                                                        this.setState({
                                                            categories: this.state.categories
                                                        })
                                                    }}
                                                        className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !category.open &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"plus"} />
                                                        }
                                                        {
                                                            category.open &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"minus"} />
                                                        }
                                                    </div>

                                                    <div className="flex flex-1 flex-col truncate">
                                                        <div className="font-bold text-xs truncate capitalize">
                                                            {category.name}
                                                        </div>
                                                        {
                                                            this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0 && this.state.metrics[category.name] && this.state.metrics[category.name].filter((item) => { return this.state.selected_metrics[item.name] }).length > 0 &&
                                                            <div className="text-xxs truncate">
                                                                You have selected <span className="font-bold">{Object.keys(this.state.selected_metrics).filter((metric_name) => { return this.state.metrics[category.name].filter((inner_item) => { return inner_item.name == metric_name }).length > 0 }).join(", ")}</span>
                                                            </div>
                                                            ||
                                                            <div className="text-xxs">
                                                                Your have {this.state.metrics[category.name] && this.state.metrics[category.name].length} metrics available
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <SlideDown closed={!category.open}>
                                                    <div className="text-xs font-bold mb-1 pl-10">
                                                        Search
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center mb-2 pl-10">
                                                        <input
                                                            onChange={(event) => {
                                                                category.search = event.target.value;
                                                                this.setState({
                                                                    categories: this.state.categories
                                                                });
                                                            }}
                                                            autocomplete="off"
                                                            placeholder={"Search ..."}
                                                            value={category.search ? category.search : ""}
                                                            className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                        />
                                                    </div>
                                                    {
                                                        this.state.metrics[category.name] && this.functions.search(category.search, this.state.metrics[category.name]).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className="flex flex-row pl-10">
                                                                        <div
                                                                            className={"cursor-pointer mb-2 flex flex-1 flex-row justify-center"}>

                                                                            <div onClick={() => {
                                                                                if (!this.state.selected_metrics) {
                                                                                    this.state.selected_metrics = {};
                                                                                }
                                                                                if (item.name in this.state.selected_metrics) {
                                                                                    delete this.state.selected_metrics[item.name];
                                                                                } else {
                                                                                    this.state.selected_metrics = {};
                                                                                    item.metric_type = "custom";
                                                                                    this.state.selected_metrics[item.name] = item;
                                                                                }
                                                                                this.props.onSelect(this.state.selected_metrics);
                                                                            }}
                                                                                className={(this.state.selected_metrics && this.state.selected_metrics[item.name] ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    this.state.selected_metrics && this.state.selected_metrics[item.name] &&
                                                                                    <div
                                                                                        className="w-full h-full rounded-full bg-purple-500" />
                                                                                }
                                                                            </div>


                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xxs capitalize">
                                                                                    {
                                                                                        (category.name == "custom" || category.name == "default") &&
                                                                                        item.channels &&
                                                                                        Object.keys(item.channels).map((channel, index) => {
                                                                                            let last = index === (Object.keys(item.channels).length - 1);
                                                                                            return (<span className="mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        category.name == "calculated" &&
                                                                                        <span>{this.functions.calculatedMetric(item)}</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </SlideDown>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.selected_metrics && Object.keys(this.state.selected_metrics).length > 0 &&
                            <div className="flex justify-center items-center">
                                <div onClick={() => {
                                    this.props.onNext();
                                }}
                                    className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                </div>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}



class KeepEmpty extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };
    componentDidMount() { }
    componentWillReceiveProps(nextProps, nextContext) { }
    functions = {};
    render() {
        return (
            <></>
        )
    }
}

export default createOrderABTest;